import { iText } from './utils/i18n';

export const openSettingsText = iText('Open Settings', 'pwa.open_settings');
export const openSettingsDesc = iText(
    'To allow ZOOM to access your current location for when you make an emergency call, enable Location Service settings.',
    'pwa.open_settings_desc',
);

export const E911_Call_Unavailable = iText('Emergency Calling is unavailable', 'pwa.e911_call_unavailable');

export const addE911AdressText = iText('Add Emergency Address', 'pwa.add_e911_address');
export const addLocationDesc = iText(
    'Add an Emergency Address for reporting your location when making an Emergency call.',
    'pwa.add_location_desc',
);

export const confirmLocationTitle = iText(
    'Is this your current location in case of an emergency call?',
    'pwa.confirm_emergency_address',
);
export const updateE911AdressText = iText('Wrong, update it', 'pwa.update_e911_address');
export const confirmText = iText('Confirm', 'pwa.confirm');
export const E911_ADDR_UPDATED = iText('Emergency address updated', 'pwa.e911_addr_updated');

export const Location_Permission_Text = iText('Location Permission', 'pwa.location_permission');
export const Emergency_Address_Text = iText('Emergency Address', 'pwa.emergency_address');
export const Allow_Access_Location_Text = iText('Allow Zoom to access my location', 'pwa.allow_acess_location');
export const Address_Type_Default_Text = iText('Default', 'pwa.address_type_default');
export const Address_Type_Detected_Text = iText('Detected', 'pwa.address_type_detected');
export const No_E911_Detected = iText('No Emergency Address detected', 'pwa.no_e911_detected');
export const Unknown_Location = iText('Unknown Location', 'pwa.unknown_Location');
export const Default_Address_Type_Hint_Text = iText(
    'Zoom will report your default Emergency Address during an emergency call',
    'pwa.default_address_type_hint',
);
export const Detected_Address_Type_Hint_Text = iText(
    'Zoom will report your detected Emergency Address during an emergency call',
    'pwa.detected_address_type_hint',
);
export const PersonalLocationOrAddressesText = iText(
    'Personal Location or Addresses',
    'pwa.personal_location_or_addresses',
);

export const Click_To_Dial_Information = iText(
    'Enabling this option will allow you to dial a phone number in a web browser by clicking on it.',
    'pwa.click_to_dial_information',
);
export const AddEmergencyAddressText = iText('Add Emergency Address', 'pwa.add_emergency_address');
export const Direct_Number_Text = iText('Direct Number', 'pwa.direct_number');
export const Company_Number_Text = iText('Company Number', 'pwa.company_number');
export const Shared_Line_Group_Text = iText('Shared Line Group', 'pwa.shared_line_group');
export const Receive_Calls_Text = iText('Receive Calls', 'pwa.receive_calls');
export const Receive_Queue_Calls_Text = iText('Receive Queue Calls', 'pwa.receive_queue_calls');
export const Local_Dialing_Text = iText('Local Dialing', 'pwa.local_dialing');
export const General_Text = iText('General', 'pwa.nav_general');
export const Audio_Text = iText('Audio', 'pwa.nav_audio');
export const Phone_Text = iText('Phone', 'pwa.nav_phone');
export const Chat_Text = iText('Team Chat', 'pwa.nav_chat');
export const Statistics_Text = iText('Statistics', 'pwa.nav_statistics');
export const ContactCenter_Text = iText('Contact Center', 'pwa.contact_center');
export const Docs_Text = iText('Docs', 'pwa.docs');
export const Click_To_Dial_SMS_Prompt = iText('Enable click-to-dial and click-to-text', 'pwa.click_to_dial');
export const Video_Text = iText('Video', 'pwa.video');

export const Phone_Network_Switch = iText('Network Switch', 'pwa.phone_network_switch');
export const Phone_Register_ID = iText('Register ID', 'pwa.phone_register_id');
export const Phone_Domain_Text = iText('Register Server', 'pwa.phone_domain');
export const Phone_Network_Interface_Text = iText('Local Network Interface', 'pwa.phone_network_interface');
export const Phone_Peer_Number_Text = iText('Peer Number', 'pwa.phone_peer_number');

export const Local_IP_And_Port = iText('Local IP and Port', 'pwa.local_ip_and_port');
export const Remote_IP_And_Port = iText('Remote IP and Port', 'pwa.remote_ip_and_port');
export const Item_Name_Text = iText('Item Name', 'pwa.item_name');
export const Send_Text = iText('Send', 'pwa.send');
export const Receive_Text = iText('Receive', 'pwa.receive');
export const Packet_Text = iText('Packet', 'pwa.packet');
export const Frequency_Text = iText('Frequency', 'pwa.frequency');
export const Packet_Loss_Text = iText('Packet Loss - Average (Max)', 'pwa.packet_loss');
export const Jitter_Text = iText('Jitter', 'pwa.jitter');
export const Bandwidth_Text = iText('Bandwidth', 'pwa.bandwidth');
export const Codec_Text = iText('Codec', 'pwa.codec');

export const No_Inbox_Seleted_Text = iText('No Inbox Seleted', 'pwa.no_inbox_seleted');
export const Inbox_Text = iText('inbox', 'pwa.inbox');
export const Inboxes_Text = iText('Inboxes', 'pwa.inboxes');
export const LOAD_VOICEMAIL_FAILED = iText('load voicemail failed, please retry', 'pwa.viocemail_load_failed');
export const View_More_Text = iText('View More', 'pwa.view_more');
export const Not_Set_Text = iText('Not Set', 'pwa.not_set');
export const Network_Delay = iText('Network Delay', 'pwa.network_delay');

export const PHONE_COPY_NUMBER_TEXT = iText('Copy Number', 'pwa.phone_copy_number');
export const PHONE_DELETE = iText('Delete', 'pwa.phone_delete');
export const PHONE_CLEAR_ALL_CALL_HISTORY = iText('Clear All Call History', 'pwa.phone_clear_all_call_history');
export const PHONE_DELETE_ALL_VOICEMAIL = iText('Delete All Voicemail', 'pwa.phone_delete_all_voicemail');
export const DELETE_ALL_TEXT = iText('Delete All', 'pwa.phone_delete_all');
export const DELETE_ALL_HISTORY_DESC_TEXT = iText(
    'You are permanently deleting all your Call History. This action cannot be undone.',
    'pwa.delete_all_history',
);
export const DELETE_ALL_VOICEMAIL_DESC_TEXT = iText(
    'You are permanently deleting all your Voicemail. This action cannot be undone.',
    'pwa.delete_all_voicemail',
);

export const CALL_DURATION_TEXT = iText('Call Duration: ', 'pwa.call_duration');
export const Do_Not_Allow_Text = iText("Don't Allow", 'pwa.do_not_allow');
export const Allow_Text = iText('Allow', 'pwa.allow');
export const Allow_Access_Location_Title_Text = iText(
    'Allow Zoom to Access Your Location',
    'pwa.allow_access_location_title',
);
export const Allow_Access_Location_Desc_Text = iText(
    'This enables Zoom to assist in determining your location, and is used only for purposes of responding to your Emergency Calls.',
    'pwa.allow_access_location_desc',
);

export const ZOOM = iText('Zoom', 'pwa.zoom');
export const OK_Text = iText('OK', 'pwa.ok');
export const Close_Text = iText('close', 'pwa.close');
export const HISTORY_TEXT_ALL = iText('All', 'pwa.pwa_history_text_all');
export const HISTORY_TOGGLE_TEXT_ALL = iText('All History', 'pwa.history_text_all');
export const HISTORY_TEXT_MISSED = iText('Missed', 'pwa.history_text_missed');
export const REJECTED_TEXT = iText('Rejected', 'pwa.rejected');
export const BLOCKED_TEXT = iText('Blocked', 'pwa.blocked');
export const CALL_CANCEL_TEXT = iText('Call Cancel', 'pwa.call_cancel');
export const NO_ANSWER_TEXT = iText('No Answer', 'pwa.no_answer');
export const CALL_FAILED_TEXT = iText('Call failed', 'pwa.call_failed');

export const HISTORY_TEXT_RECORDING = iText('Recording', 'pwa.history_text_recording');
export const MISSED_ARRAY = [
    HISTORY_TEXT_MISSED,
    REJECTED_TEXT,
    BLOCKED_TEXT,
    CALL_CANCEL_TEXT,
    NO_ANSWER_TEXT,
    CALL_FAILED_TEXT,
];
export const SHARED_GROUP = iText('SHARED GROUP', 'pwa.shared_group');
export const MEET_WITH_VIDEO = iText('Meet With Video', 'pwa.meet_with_video');
export const MEET_WITHOUT_VIDEO = iText('Meet Without Video', 'pwa.meet_without_video');
export const INCOMING_CALL = iText('Incoming call', 'pwa.incoming_call');

// Initial ui page.

export const JOIN_MEETING = iText('Join Meeting', 'pwa.join_meeting');
export const MEETING_ID_PLN = iText('Meeting ID or Personal Link Name', 'pwa.meeting_id_pln');
export const NEW_MEETING = iText('New Meeting', 'pwa.new_meeting');
export const Schedule = iText('Schedule', 'pwa.schedule');
export const Return_To_Meeting = iText('Return to Meeting', 'pwa.return_to_meeting');
export const JOIN = iText('Join', 'pwa.join_button');
export const CANCEL = iText('Cancel', 'pwa.cancel_button');
export const YOUR_NAME = iText('Your Name', 'pwa.your_name');
export const SAVE = iText('Save', 'pwa.save');
export const TOS = iText(
    'By clicking "Join", you agree to our Terms of Service and Privacy Statement',
    'pwa.terms_of_service',
);
export const BACK = iText('Back', 'pwa.back_button');
export const ENTER_MEETING_PASSCODE = iText('Enter Meeting Passcode', 'pwa.enter_meeting_passcode');
export const MEETING_PASSCODE = iText('Meeting Passcode', 'pwa.meeting_passcode');
export const SIGN_IN_BUTTON = iText('Sign In', 'pwa.sign_in_button');
export const ABOUT = iText('About Zoom', 'pwa.about_zoom');
export const ABOUT_VERSION = iText('Version:', 'pwa.version');

export const ABOUT_CONTENT = (time: string) =>
    iText('Copyright \u00A9{0} Zoom Video Communications, Inc. All rights reserved.', 'pwa.about_content3', [time]);

export const ABOUT_OPEN_SOURCE = iText('Open Source Software', 'pwa.about_open_source');
export const MY_PROFILE = iText('My Profile', 'pwa.my_profile');
export const ABOUT_BUTTON = iText('About', 'pwa.about_button');
export const SIGN_OUT_BUTTON = iText('Sign Out', 'pwa.sign_out_button');
export const HOME_BUTTON = iText('Home', 'pwa.home_button');
export const CONTACTS_BUTTON = iText('Contacts', 'pwa.contacts_button');
export const CONTACTS_SEARCH = iText('Search', 'pwa.contacts_search');
export const MEETINGS_TEXT = iText('Meetings', 'pwa.meetings');
export const Whiteboards_Text = iText('Whiteboards', 'pwa.whiteboards');
export const CONTACTS_NO_CONTACT_SELECTED = iText(
    'View Contact info by clicking a contact in the left panel',
    'pwa.no_contact_selected',
);

export const CONTACTS_COMPANY = iText('Company', 'pwa.contacts_company');
export const CONTACTS_DEPARTMENT = iText('Department', 'pwa.contacts_department');
export const CONTACTS_JOB_TITLE = iText('Job Title', 'pwa.contacts_job_title');
export const CONTACTS_TIME_ZONE = iText('Time Zone', 'pwa.contacts_time_zone');
export const CONTACTS_EXTENSION = iText('Extension', 'pwa.contacts_extension');
export const CONTACTS_OTHER = iText('Other', 'pwa.contacts_other');

export const CONTACTS_EMAIL = iText('Email', 'pwa.contacts_email');
export const CONTACTS_DEFAULT = iText('default', 'pwa.contacts_default');
export const CONTACTS_OOO = iText('Out of Office', 'pwa.contacts_presence_out_of_office');
export const CONTACTS_OFFLINE = iText('Offline', 'pwa.contacts_presence_offline');
export const CONTACTS_ONLINE = iText('Online', 'pwa.contacts_presence_offline');
export const CONTACTS_DND = iText('Do Not Disturb', 'pwa.contacts_dnd');
export const CONTACTS_DND_LOWER = iText('Do not disturb', 'pwa.contacts_dnd_lower');
export const CONTACTS_IN_ZOOM_MEETING = iText('In a Zoom meeting', 'pwa.contacts_in_zoom_meeting');
export const CONTACTS_CALENDAR_EVENT = iText('In a calendar event', 'pwa.contacts_calendar_event');
export const CONTACTS_ON_A_CALL = iText('On a call', 'pwa.contacts_on_a_call');
export const CONTACTS_MOBILE = iText('Mobile', 'pwa.contacts_presence_mobile');
export const CONTACTS_AVAILABLE = iText('Available', 'pwa.contacts_presence_available');
export const CONTACTS_AWAY = iText('Away', 'pwa.contacts_presence_away');
export const CONTACTS_BUSY = iText('Busy', 'pwa.contacts_presence_busy');
export const CONTACTS_PRESENTING = iText('Presenting', 'pwa.contacts_presence_presenting');
export const CONTACTS_NO_RESULT = iText('No result', 'pwa.contacts_no_result');
export const CONTACTS_LOADING = iText('Loading', 'pwa.contacts_loading');
export const PROFILE_DROP_SETTINGS = iText('Settings', 'pwa.profile_drop_settings');
export const MEET_BUTTON = iText('Meet', 'pwa.meet_button');
export const INVITE_TO_MEETING = iText('Invite to Meeting', 'pwa.invite_to_meeting');
export const MEET_WITH_VIDEO_TEXT = iText('Meet with video', 'pwa.meet_with_video_l');

export const LANGUAGE = iText('Language', 'pwa.language');

export const HIDE_CALL_WHEN_IN_MEETING = iText('Hide incoming calls while in a meeting', 'pwa.hide_call_while_meeting');
export const Can_Not_Access_Micro = iText('Can not access your microphone', 'pwa.can_not_access_micro');
export const JOIN_NEW_MEETING = iText('Join New Meeting?', 'pwa.join_new_meeting');
export const LEAVE_THIS_TO_JOIN_NEW_MEETING = iText(
    'You will have to leave this meeting to join the new meeting',
    'pwa.leave_current_to_join_new',
);
export const LEAVE_AND_JOIN = iText('Leave & Join', 'pwa.leave_and_join');

export const CALL_OR_SEND_MESSAGE = iText(
    'Would you like to call or send an SMS to the number you selected?',
    'pwa.click_to_dial_prompt_message',
);

export const PHONE_NUMBER_INVALID = iText(
    'The phone number you selected is invalid.',
    'pwa.click_to_dial_invalid_phone_number',
);

export const Announcement_Text = iText('Announcement', 'pwa.announcement');
export const Announcement_Body_Text = iText(
    'Meeting hosting and scheduling capabilities are not allowed for your user account.',
    'pwa.announcement_body',
);
export const TURN_OFF = iText('Turn Off', 'pwa.turn_off');
export const MINUTES_TEXT_F = (minutes: number) => iText('{0} minutes', 'pwa.minutes', [minutes]);
export const HOURS_TEXT_F = (hours: number) => iText('{0} hours', 'pwa.hours', [hours]);

export const SIGNOUT_CONFLICT = iText(
    "You're currently signed in to your Zoom account in another browser tab or device.",
    'pwa.signout_conflict',
);
export const SIGNOUT_REVOKE_TOKEN = iText(
    'Your session has ended and you need to sign in again',
    'pwa.signout_revoke_token',
);

export const ENTER_WAITING_F_1 = (who: string) =>
    iText('{0} entered the waiting room', 'pwa.waitingroom_tip_msg1', [who]);
export const ENTER_WAITING_F_2 = (howmany: number) =>
    iText('{0} people entered the waiting room', 'pwa.waitingroom_tip_msg2', [howmany]);
export const LIVE_TRANSCRIPTION_ENABLED_TEXT = iText(
    'Live Transcription (Closed Captioning) has been enabled',
    'pwa.live_transcription_enabled',
);
export const MUTED_BY_HOST_TEXT = iText('The host muted you', 'pwa_muted_by_host');
export const UNMUTE_REQUEST_TEXT = iText('The host would like you to speak', 'pwa.unmute_request');
export const VIDEO_REQUEST_TEXT = iText('The host has asked you to start your video', 'pwa.video_request');
export const RECORDING_TEXT = iText('This meeting is being recorded', 'pwa.recording');
export const INVITED_TO_BO_TEXT_F = (hostName: string, roomName: string) =>
    iText('{0} is inviting you to join breakout room {1}', 'pwa.invite_to_bo', [hostName, roomName]);
export const BO_TIME_UP_TEXT = iText('Time is up for breakout rooms', 'pwa.bo_time_out');
export const LIVE_TRANSCRIPTION_REQUEST_TEXT_F = (Name: string) =>
    iText('{0} requests that Live Transcription be enabled for the meeting', 'pwa.live_transcription_request', [Name]);
export const POLL_INBOX_TEXT = iText('You have a meeting poll', 'pwa.poll_inbox');
export const BACK_TO_MEETING_TEXT = iText('Back to meeting', 'pwa.back_to_meeting');
export const BACK_TO_MEETING_1 = iText('Back to Meeting', 'pwa.back_to_meeting_1');
export const END_BY_HOST_TEXT = iText('The meeting has been ended by the host', 'pwa.meeting_ended_by_host');
export const RETURN_MAIN_SESSION_TEXT_F = (inviterName: string) =>
    iText('{0} is inviting you to return to main session.', 'pwa.return_main_session', [inviterName]);
export const PROMOTE_TO_PANELIST_TEXT = iText(
    'The host would like to promote you to be a panelist',
    'pwa.promote.to.panelist',
);
export const BAN_VIDEO_TEXT = iText(
    'You cannot start your video because the host has stopped it',
    'pwa.ban_video_desc',
);
export const REMOVED_BY_HOST_TEXT = iText('You have been removed from this meeting by the host', 'pwa.removed_by_host');
export const RENAMED_BY_HOST_TEXT_F = (name: string) =>
    iText('The host renamed you to {0}', 'pwa.renamed.by.host', [name]);

export const CHOOSE_LANG = iText('Choose Language', 'pwa.choose_lang');
export const BAN_UNMUTE_AUDIO_TEXT = iText(
    'The host is not allowing participants to unmute themselves',
    'pwa.ban_unmute_audio',
);
export const YOU_HAVE_N_NOTIFICATIONS_F = (num: number) =>
    iText('You have {0} notifications', 'pwa.have_n_notifications', [num]);
export const ON_HOLD_TEXT = iText('On Hold', 'pwa.on_hold');
export const CALLING_NUM_TEXT_F = (num: string) => iText('Calling {0}...', 'pwa.calling_number', [num]);

export const POLL_RESULT_SHARED_TEXT = iText('The host shared the poll results', 'pwa.poll_result_shared');
export const BO_WILL_CLOSE_TEXT = iText(
    'Breakout rooms will close soon. You will return to main session automatically.',
    'pwa.bo_will_close',
);
export const HELP_REQUEST_TEXT_F = (name: string, roomName: string) =>
    iText('{0} in {1} asked for help', 'pwa.help_request', [name, roomName]);

export const SHARED_CONTACT_LISTS_TEXT = iText('Shared Contact Lists', 'pwa.shared.contact.lists');
export const MY_CONTACT_TEXT = iText('My Contacts', 'pwa.my.contact');
export const ALL_CONTACT_TEXT = iText('All Contacts', 'pwa.all.contact');

export const COMPANY_CONTACT_TEXT = iText('Company Contacts', 'pwa.company.contacts');
export const EXTERNAL_TEXT = iText('External', 'pwa.external');
export const ZOOM_ROOMS_TEXT = iText('Zoom Rooms', 'pwa.zoom.rooms');
export const BOTS_TEXT = iText('Bots', 'pwa.bots');
export const YOU_ARE_SIGN_OUT_TEXT = iText("You're signed out", 'pwa.you_are_sign_out');
export const ONLY_ONE_IMAGE_AT_ONCE = iText('You can only send 1 image at once', 'pwa.send_one_img_once');
export const CLOUD_RECORDING_EXCEED_LIMIT_TEXT_ADMIN = iText(
    'Cloud recording has been disabled because your account has used up all of the available cloud storage space.',
    'pwa.cloud_recording_exceed_limit_admin',
);
export const CLOUD_RECORDING_EXCEED_LIMIT_TEXT = iText(
    'Cloud recording has been disabled. Contact your account admin for more information',
    'pwa.cloud_recording_exceed_limit',
);
export const VIEW_DETAILS_TEXT = iText('View Details', 'pwa.view_details');

export const START_WITH_VIDEO_TEXT = iText('Start with video', 'pwa.start_with_video');
export const COPY_PMI_ID_TEXT = iText('Copy ID', 'pwa.copy_pmi_id');
export const PMI_SETTINGS_TEXT = iText('PMI Settings', 'pwa.pmi_settings');
export const UNABLE_PLAY_ENCRYPTED_VOICEMAIL = iText(
    'Unable to play encrypted voicemail. Use your desktop or mobile app to play voicemail.',
    'pwa.unable_play_encrypted_voicemail',
);
export const UNABLE_PLAY_VIDEOMAIL = iText(
    'Unable to play videomail, use your Native Client to play videomail',
    'pwa.unable_play_videomail',
);

export const LEAVE_JOIN_NEW_MEETING = iText('Leave current meeting and join new one?', 'pwa.leave_join_new_meeting');
export const ZOOM_VIDEO_MEETING = iText('Zoom Video Meeting', 'pwa.zoom_video_meeting');
export const ONE_IS_INVITING_YOU = (who: string) =>
    iText('{0} is inviting you to a meeting.', 'pwa.one_is_inviting_you', [who]);

export const IS_INVITING_YOU = iText('is inviting you to a meeting...', 'pwa.is_inviting_you');
export const ONE_IS_UNAVAILABLE = (who: string) => iText('{0} is unavailable', 'pwa.one_is_unavailable', [who]);
export const OVERALL = iText('Overall', 'pwa.overall');

// chat
export const CHAT_POPOVER_ABAILABLE_TITLE = iText('Team Chat is now available!', 'pwa.chat_popover_title');
export const CHAT_POPOVER_ABAILABLE_CONTENT_1 = iText(
    'You can send and receive direct messages, and chat in channels.',
    'pwa.chat_popover_content_1',
);

export const LOADING_WHITEBOARD = iText('Loading your whiteboard', 'pwa.loading_whiteboard');

export const OTP_NOTIFICATION_TEXT = iText(
    'Your Zoom account is being signed in on another device.',
    'pwa.otp_notification',
);

export const OTP_SIGNING_IN_ON_ANOTHER_DEVICE = iText('Signing in on another device', 'pwa.otp_signing_in');
export const OTP_TIME = iText('Time', 'pwa.otp_time');
export const OTP_BROWSER = iText('Browser', 'pwa.otp_browser');
export const OTP_OS = iText('OS', 'pwa.otp_operating_system');
export const OTP_LOCATION = iText('Location', 'pwa.otp_location');
export const OTP_ALLOW = iText('Allow', 'pwa.otp_allow');
export const OTP_DENY = iText('Deny', 'pwa.otp_deny');
export const OTP_CHANGE_PASSWORD = iText('Change your password', 'pwa.otp_change_password');
export const OTP_ENABLE_TWO_FACTOR_AUTH = iText(
    "If this wasn't you, we recommend that you update your password and enable two-factor authentication to secure your account.",
    'pwa.otp_two_auth',
);
export const OTP_DO_NOT_CHANGE = iText("Don't Change", 'pwa.otp_do_not_change');
export const OTP_CHANGE = iText('Change Password', 'pwa.otp_change');
export const OTP_ERROR_OCCURRED = iText('Error occurred', 'pwa.otp_error_occured');

export const MISSED_CALL_TEXT = iText('Missed Call', 'pwa.call_missed_call');

export const MSG_TO_CHANNEL = iText('Message Invitees', 'pwa.msg_to_channel');

export const FORCED_BREAK_SPECIAL_OFFER = iText('SPECIAL OFFER', 'pwa.forced_break_special_offer');
export const UPGRADE = iText('Upgrade', 'pwa.upgrade_button');
export const SYNC_BTN_ON_HEADSET = iText('Sync buttons on headset', 'pwa.sync_buttons_on_headset');

export const CLEAR_HISTORY_TEXT = iText('Clear History', 'pwa.clear_history');
export const SESSION_WILL_EXPIRE = iText('Your session will expire in {0}.', 'pwa.sign_session_will_expire');
export const SESSION_EXPIRE_TIME_SUBTITLE = iText(
    'Your session is about to expire',
    'pwa.modal_session_expire_time_subtitle',
);
export const SESSION_EXPIRE_TIME_CONTENT = iText(
    'Your session will expire in {0} due to the admin setting. You can sign in now to renew your session.',
    'pwa.modal_session_expire_time_content',
);

export const CHAT_POST_MEETING_NOTICE_TITLE = iText('Pick up where you left off', 'pwa.chat.post_meeting_notice_title');

export const CHAT_POST_MEETING_NOTICE = iText(
    'Messages sent during {0} will now appear in Team Chat under a group chat with the same name',
    'pwa.chat.post_meeting_notice',
);
export const SOMETHING_WRONG = iText('Something went wrong', 'pwa.phone.something.went.wrong');
export const GO_TO_CHAT = iText('Go to Chat', 'pwa.chat.go_to_chat');

export const Do_Not_Show_Again_Text = iText("Don't show again", 'pwa.do_not_show-again');
export const Notice_Text = iText('Notice', 'pwa.notice');
export const Meeting_List_Toggle_Text = iText('Meeting history list', 'pwa.meetingList.toggle');
export const meeting_History_Item_Text_F = (topic: string, meetingNo: number) =>
    iText('the topic is {0}, and ID is {1}', 'pwa.meeting_history_item_text', [topic, meetingNo]);
export const More_Options_Text = iText('More options', 'pwa.more_options');
export const YOU = iText('You', 'pwa.more_info_you');
export const Accept_Text = iText('Accept', 'pwa.accept');
export const Skip_Text = iText('Skip', 'pwa.skip');
export const Decline_Text = iText('Decline', 'pwa.decline');
export const Hold_Accept_Text = iText('Hold & Accept', 'pwa.hold_accept');
export const SMS_TEXT = iText('SMS', 'pwa.sms_text');

export const Continue = iText('Continue', 'pwa.continue');
export const Chat_Text_2 = iText('Chat', 'pwa.chat');
export const Mute_Text = iText('Mute', 'pwa.mute');
export const Unmute_Text = iText('Unmute', 'pwa.unmute');
export const Leave_Meeting_Accept = iText('Leave Meeting & Accept', 'pwa.leave_meeting_accept');
export const Hold_Meeting_Audio_Accept = iText('Hold Meeting Audio & Accept', 'pwa.hold_meeting_audio_accept');
export const Video_Call = iText('Video Call', 'pwa.video_call');
export const Voice_Call = iText('Voice Call', 'pwa.voice_call');
export const Facebook_Messenger = iText('Facebook Messenger', 'pwa.facebook_messenger');
export const Outbound_Campaign = iText('Outbound Campaign', 'pwa.outbound_campaign');
export const Transfer_To_You = iText('Transfer to You', 'pwa.transfer_to_you');
// export const To_Someone = (name: string) => iText('To {0}', 'pwa.To_someone', [name]);
export const To = iText('To', 'pwa.To');

export const Has_Zoom_Meeting_InProgress = iText('You have a meeting in progress', 'pwa.zm_m_in_progress');
export const Has_Zoom_Phone_InProgress = iText('You have a phone call in progress', 'pwa.zm_p_in_progress');
export const ZCC_Phone_Over_Zoom_Meeting = iText(
    'If you continue to make a voice call, your audio will be switched from the meeting to your voice call.',
    'pwa.zcc_p_over_zm_m',
);
export const ZCC_Phone_Over_Zoom_Phone = iText(
    'If you continue to make a voice call, your audio will be switched from the phone call to your voice call.',
    'pwa.zcc_p_over_zm_p',
);
export const ZCC_Video_Over_Zoom_Meeting = iText(
    'If you continue to make a video call, you have to leave current zoom meeting',
    'pwa.zcc_v_over_zm_m',
);
export const ZCC_Video_Over_Zoom_Phone = iText(
    'If you continue to make a voice call, your audio will be switched from the phone call to your video call.',
    'pwa.zcc_v_over_zm_m',
);

export const Contact_Center_Banner_Text = (name: string) =>
    iText('Contact Center - {0}', 'pwa.zcc.banner_text', [name]);
export const INVITE = iText('Invite', 'pwa.invite_button');
export const ADD_A_CONTACT = iText('Add a contact', 'pwa.add_a_contact');
export const INVITE_ZOOM_CONTACT = iText('Invite a Zoom Contact', 'pwa.contact.invite_zoom_contact');
export const INVITE_TO_CONTACT = iText('Invite to Zoom', 'pwa.contact.invite_to_contact');
export const EMAIL_ADDRESS = iText('Email Address', 'pwa.contact.email_address');
export const ENTER_EMAIL_ADDRESS = iText('Enter email address', 'pwa.contact.enter_email_address');
export const EMAIL_ADDRESS_DESC = iText(
    'if this user accepts your request, your profile information (including your status) will be visible to this contact. You can also meet and chat with this contact.',
    'pwa.contact.email_address_desc',
);
export const ENTER_ONE_EMAIL = iText('You can only add one contact at a time', 'pwa.contact.enter_one_email');
export const CANNOT_ADD_SELF = iText('You cannot add yourself as a contact', 'pwa.contact.cant_not_add_self');
export const INVITE_SEND_SUCCESS = iText('An invitation has been sent to {0}', 'pwa.contact.invite_send_success');

export const START_CHAT = iText('Start Chat', 'pwa.contact.start_chat');
export const ALREADY_CONTACT = iText('{0} is already a contact', 'pwa.contact.already_contact');

export const DELETE_CONTACT_F = (name: string) =>
    iText(
        'You will not be able contact {0}. This action will also clear your chat history with {0}.',
        'pwa.delete_contact_f',
        [name],
    );
export const DELETE_CONTACT = iText('Delete Contact', 'pwa.delete_contact');
export const More_Text = iText('more', 'pwa.more');

export const NOTIFY_INVITE_ACCEPTED_F = (name: string) =>
    iText('{0} accepted your contact request', 'pwa._notification_invite_contact_accepted', [name]);

export const NOTIFY_INVITE_DECLINED_F = (name: string) =>
    iText('{0} declined your contact request', 'pwa._notification_invite_contact_declined', [name]);

export const NOTIFY_INVITE_ME_F = (name: string) =>
    iText('{0} would like to add you as a contact', 'pwa._notification_invite_me', [name]);
export const On_CALL_WITH_F = (name: string) => iText('On a call with {0}', 'pwa.presence._on_call_with', [name]);

export const PENDING = iText('Pending', 'pwa.contact.pengding');
export const GROUPING = iText('grouping', 'pwa.grouping');

export const LEVEL_F = (index: number) => iText('level {0}', 'pwa.contact_level', [index]);
export const NOT_SELECTED = iText('Not selected', 'pwa.not_selected');
export const ITEMS_ENCLOSE_F = (num: number) => iText('{0} items enclose', 'pwa.contact_items_enclose', [num]);
export const CONTACT_INDEX_OF_F = (num1: number, num2: number) =>
    iText('{0} of {1}', 'pwa.contact_items_index', [num1, num2]);
export const NOT_NOW = iText('Not Now', 'pwa.not.now');

export const Phone_Setup_Title = iText('Set up your phone now', 'pwa.phone.setup.title');
export const Phone_Setup_Desc = iText('Complete initial setup to start using Zoom Phone', 'pwa.phone.setup.desc');
export const Phone_Setup_Btn = iText('Setup', 'pwa.phone.setup.btn');

export const Phone_Upgrade_Title = iText('Connect to the world with Zoom Phone', 'pwa.phone.upgrade.title');
export const Phone_Upgrade_Desc = iText(
    'Zoom phone is a feature-rich cloud phone system for businesses of all sizes. Upgrade to start using Zoom Phone',
    'pwa.phone.upgrade.desc',
);

export const Phone_Support_Title = iText('Use Phone on Zoom Web App', 'pwa.phone.support.title');
export const Phone_Support_Desc = iText(
    'Complete the setup for Zoom Phone on the Zoom Web App',
    'pwa.phone.support.desc',
);
export const Phone_Support_Btn = iText('Learn more about setup', 'pwa.phone.support.btn');

export const Learn_About_Phone = iText('Learn about Zoom Phone', 'pwa.learn.about.phone');
export const Phone_Contact_Admin = iText(
    'To use Phone in Zoom Web App, contact your admin.',
    'pwa.phone.contact.admin',
);
export const History_List_Text = iText('History List', 'pwa.phone_history_list');
export const Voicemail_List_Text = iText('Voicemail List', 'pwa.phone_voicemail_list');
export const SMS_List_Text = iText('SMS List', 'pwa.phone_sms_list');

export const Error_Contact_Us = iText(
    'An error occurred. Refresh or if the error persists <a href={0}>Contact Us</a> for help.',
    'pwa.error.contact_us',
);

export const CAMERA_CONTROL_GROUP = iText('Camera Control Group', 'pwa.contact_camera_control_group');

export const ADD_CAMERA_CONTROL_GROUP_MODAL_TITLE_F = (name: string) =>
    iText('Add {0} to Camera Control Group', 'pwa.contact_add_camera_control_modal_title', [name]);
export const ADD_CAMERA_CONTROL_GROUP_MODAL_CONTENT = iText(
    `If you click "Add", they will be able to control your device's camera for all future meetings.`,
    'pwa.contact_add_camera_control_modal_content',
);

export const ADD_CAMERA_CONTROL_GROUP = iText('Add to Camera Control Group', 'pwa.contact_add_camera_control_group');
export const ADD_CAMERA_CONTROL_GROUP_F = (name: string) =>
    iText('You added {0} to your Camera Control Group', 'pwa.contact_add_camera_control_f', [name]);

export const REMOVE_CAMERA_CONTROL_GROUP = iText(
    'Remove from Camera Control Group',
    'pwa.contact_remove_camera_control_group',
);
export const REMOVE_CAMERA_CONTROL_GROUP_F = (name: string) =>
    iText('You have removed {0} from your Camera Control Group', 'pwa.contact_remove_camera_control_f', [name]);

export const Add_Text = iText('Add', 'pwa.add');
export const Show_Information_Text = iText('show information', 'pwa.show_information');
export const Hide_Information_Text = iText('hide information', 'pwa.hide_information');

export const New_Text = iText('New', 'pwa.new');
export const Start_New_To_Upgrade = iText('Start a new meeting with caller', 'pwa.upgrade.start');

export const Merge_Text = iText('Merge', 'pwa.merge');
export const Merge_To_Upgrade = iText('Merge caller to your current meeting', 'pwa.upugrade.merge');

export const Connecting_Text = iText('connecting', 'pwa.connect_status_connecting');
export const Not_Connected_Text = iText('Not connected', 'pwa.connect_status_not_connected');

export const REMOVE_PINNED_TEXT = iText('Remove from toolbar', 'pwa.header_tabs_remove_pinned');
export const ADD_PINNED_TEXT = iText('Pin to toolbar', 'pwa.header_tabs_add_pinned');
export const CONFIGURE_MENU = iText('Configure menu', 'pwa.header_tabs_configure_menu');
export const GLOBAL_NAV_SETTING_TEXT = iText(
    'Items are added to toolbar when accessed',
    'pwa.settings_global_nav_notice',
);
export const NAVIGATION_TEXT = iText('Navigation', 'pwa.settings_navigation');
export const RESET_TO_DEFAULT = iText('Reset to default', 'pwa.settings_reset_to_default');
export const PINNED_TEXT = iText('Pinned', 'pwa.settings_pinned');
export const DRAG_TO_MORE_TEXT = iText(
    'Drag items here to remove them from the toolbar',
    'pwa.header_tabs_more_drag_text',
);
export const DRAG_TO_PINNED_TEXT = iText(
    'Drag items here to add them to the toolbar',
    'pwa.header_tabs_pinned_drag_text',
);
export const From_Text = iText('From', 'pwa.from');
export const Skip_Reason_Text = iText('Skip Reason', 'pwa.skip_reason');
export const Select_Reason_Text = iText('Select reason', 'pwa.select_reason');
export const Transfer_Note_Text = iText('Transfer Note', 'pwa.transfer_note');

export const ObfuscateTip = iText(
    'To ensure your privacy, keypad entries are hidden and muted.',
    'pwa.phone.obfuscate',
);

export const ObfIconText = iText('learn more about keypad button', 'pwa.phone.obf.icon');
export const Transfer_Participant_To_Waiting_Room = iText(
    'The participants in your meeting have transferred to the waiting room',
    'pwa.meeting_transfer_to_waiting_room',
);
export const MESSAGE_WILL_DISAPPEAR_F = (time: number) =>
    iText('This message will auto disappear in {0}s', 'pwa.message_message_will_disappear_f', [time]);

export const SETTINGS_THEME_TITLE = iText('Theme', 'pwa.settings_theme_title');
export const SETTING_THEME_TEXT = iText('Only applied when the system is using light mode', 'pwa.settings_theme_text');
export const SETTINGS_THEME_POPOVER_1 = iText(
    'Color themes are available when the app is set to light mode or the system setting is in light mode.',
    'pwa.settings_theme_popover_1',
);
export const SETTINGS_THEME_POPOVER_2 = iText(
    'The "Classic" theme applies a gray color to the app navigation toolbar, and sets the meeting and webinar window to a dark gray color.',
    'pwa.settings_theme_popover_2',
);
export const SETTINGS_THEME_POPOVER_3 = iText(
    'The dark gray color is automatically applied to meetings or webinars which use a custom wallpaper.',
    'pwa.settings_theme_popover_3',
);

export const CLASSIC_THEME_TEXT = iText('Classic', 'pwa.settings_classic_theme');
export const BLOOM_THEME_TEXT = iText('Bloom', 'pwa.settings_bloom_theme');
export const AGAVE_THEME_TEXT = iText('Agave', 'pwa.settings_agave_theme');
export const ROSE_THEME_TEXT = iText('Rose', 'pwa.settings_rose_theme');
export const No_Skip_Available = iText('You have no skips availale', 'pwa.no_skip_available');

export const Configure_Calendar = iText('Configure Your Settings', 'pwa.config_calendar');

export const ReAuth_Calendar = iText(
    "Your calendar isn't connected. This could happen if you changed your password or need to authenticate again.",
    'pwa.re_auth_calendar',
);
export const Receive_Shared_Calls_Text = iText('Receive Shared Calls', 'pwa.receive_shared_calls');
export const Thank_You_Text = (webinarOrMeeting: string) =>
    iText('Thank you for attending the {0}', 'pwa.thank_you_for_joining_text', [webinarOrMeeting]);
export const WEBINAR_TEXT = iText('webinar', 'pwa.feedback.webinar');
export const MEETING_TEXT = iText('meeting', 'pwa.feedback.meeting');

export const GIVE_FEEDBACK_TEXT = iText('Give Feedback', 'pwa.feedback_give_feedback');
export const PRODUCT_TEXT = iText('Product Area', 'pwa.feedback_product');
export const FEEDBACK_TEXT = iText('Feedback', 'pwa.feedback_text');

export const SCORE_TEXT = iText('How satisfied are you with your experience?', 'pwa.feedback_score_question');

export const FEEDBACK_PREFILLED_TEXT = iText(
    'Thank you for your ideas, comments, and suggestions.',
    'pwa.feedback_prefilled_text',
);

export const FEEDBACK_TECHNICAL_SUPPORT_TEXT = iText(
    `For technical support, please visit <a href={0}> Support </a>.`,
    'pwa.feedback_technical_support_text',
);

export const SATISFACTION_SCORE_WARNING = iText('Satisfaction score is required.', 'pwa.feedback_satsifcation_warning');
export const VERY_SATISFIED_TEXT = iText('Very Satisfied', 'pwa.feedback_satisfied');
export const VERY_DISSATISFIED_TEXT = iText('Very Dissatisfied', 'pwa.feedback_dissatisfied');

export const GIVE_FEEDBACK_BUTTON_TEXT = iText('Give Feedback...', 'pwa.giveFeedback_btn_text');

export const THANK_YOU_RESPONSE_TOAST = iText('Thank you for your feedback!', 'pwa.feedback_response_toast');
export const CHAT_HISTORY_TEXT = iText('Chat History', 'pwa.chat.history');
export const SHARED_TEXT = iText('shared', 'pwa.shared');
