import { WebimPresenceType } from '@zoom/pwa-webim';
import {
    CONTACTS_AWAY,
    CONTACTS_BUSY,
    CONTACTS_AVAILABLE,
    CONTACTS_MOBILE,
    CONTACTS_PRESENTING,
    CONTACTS_OOO,
    CONTACTS_OFFLINE,
    CONTACTS_ONLINE,
    CONTACTS_DND_LOWER,
    CONTACTS_IN_ZOOM_MEETING,
    CONTACTS_CALENDAR_EVENT,
    CONTACTS_ON_A_CALL,
    On_CALL_WITH_F,
} from '../../resource';

export const NSCommand = {
    Invite: 0,
    Old_Decline: 1,
    Accepted: 2,
    Decline: 3,
    Cancel: 4,
};

export function getPresenceTxt(cls, callPartner) {
    switch (cls) {
        case WebimPresenceType.away:
            return CONTACTS_AWAY;
        case WebimPresenceType.busy:
            return CONTACTS_BUSY;
        case WebimPresenceType.available:
            return CONTACTS_AVAILABLE;
        case WebimPresenceType.mobile:
            return CONTACTS_MOBILE;
        case WebimPresenceType.presenting:
            return CONTACTS_PRESENTING;
        case WebimPresenceType.zoommeeting:
            return CONTACTS_IN_ZOOM_MEETING;
        case WebimPresenceType.calendar:
            return CONTACTS_CALENDAR_EVENT;
        case WebimPresenceType.pbx:
            if (callPartner) {
                return On_CALL_WITH_F(callPartner);
            }
            return CONTACTS_ON_A_CALL;
        case WebimPresenceType.dnd:
            return CONTACTS_DND_LOWER;
        case WebimPresenceType.ooo:
            return CONTACTS_OOO;
        default:
            return CONTACTS_OFFLINE;
    }
}
