import {
    makeLoggerWithoutCache,
    makeLoggerWithEncryption,
    reportToGlobalTracing,
    reportToGlobalTracingByBeacon,
} from './';
import NavigationMetrics from './log-pieces/NavigationMetrics';

// onload
const onLoadLogTag = 'Portal_OnLoad';
export const onLoadLogger = makeLoggerWithEncryption(onLoadLogTag);
export const uploadOnloadLogs = () => {
    return reportToGlobalTracing({ filter: onLoadLogTag });
};

// navigation
const navigationLogTag = 'Portal_Navigation';
export const navigationLogger = makeLoggerWithoutCache(navigationLogTag);

export const uploadNavigationLog = (logs: any) => {
    return reportToGlobalTracingByBeacon(logs, navigationLogTag);
};

export const navigationMetrics = new NavigationMetrics({ reportLog: uploadNavigationLog });

// webclient
const webclientLogTag = 'Portal_WebClient';
export const webclientLogger = makeLoggerWithEncryption([webclientLogTag]);
export const uploadWebclientLogs = () => {
    return reportToGlobalTracing({ filter: webclientLogTag });
};

// zoom phone
export const Phone_Log_Tag = 'Portal_Zoom_Phone';
export const phoneLogger = makeLoggerWithEncryption([Phone_Log_Tag]);
export const uploadPhoneLogs = () => {
    return reportToGlobalTracing({ filter: Phone_Log_Tag });
};

// user session
const userSessionLogTag = 'Portal_User_Session';
export const userSessionLogger = makeLoggerWithEncryption([userSessionLogTag]);
export const uploadUserSessionLogs = () => {
    return reportToGlobalTracing({ filter: userSessionLogTag });
};
