import { useEffect } from 'react';
import { WebimPresenceType } from '@zoom/pwa-webim';
import { useAppSelector } from '../../../store/store-hooks';
import { xmppAgent } from '../../../app-init';
import { isXmppConnectedSelector } from '../../../store/xmpp/xmpp-store';
import { PresenceBadge } from '../../../components/Presence';

interface IUsePresence {
    jid: string;
    needQuery?: boolean;
    isAddon?: boolean;
}

interface IReturnUsePresence {
    presenceType: WebimPresenceType;
    PresenceBadge: () => JSX.Element;
}

export default function usePresence({ jid, needQuery = true, isAddon = false }: IUsePresence): IReturnUsePresence {
    const presences = useAppSelector((state) => state.contacts.presences);
    const isXmppConnected = useAppSelector(isXmppConnectedSelector);

    useEffect(() => {
        if (jid && isXmppConnected && !isAddon) {
            return;
        }

        if (needQuery) {
            xmppAgent.queryPresence(jid);
        }
    }, []);

    if (!jid || !isXmppConnected) {
        return {
            presenceType: WebimPresenceType.offline,
            PresenceBadge: () => PresenceBadge({ presenceType: WebimPresenceType.offline }),
        };
    }

    if (isAddon) {
        return {
            presenceType: WebimPresenceType.available,
            PresenceBadge: () => PresenceBadge({ presenceType: WebimPresenceType.available }),
        };
    }

    const presence = presences[jid];
    if (!presence) {
        return {
            presenceType: WebimPresenceType.offline,
            PresenceBadge: () => PresenceBadge({ presenceType: WebimPresenceType.offline }),
        };
    }

    return {
        presenceType: presence.presenceType,
        PresenceBadge: () => PresenceBadge({ presenceType: presence.presenceType }),
    };
}
