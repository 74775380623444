import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getMediaDeviceId, getMediaDeviceLabel } from '../../utils';
import { getLanguageInCookie } from '../../utils/Cookies/utils';
import { IDevice } from '@zoom/pwa-webim';
import easyStore from '../../utils/storage';
import { storageKey } from './storage-keys';
import { isNull } from 'lodash-es';

export interface IMeetingHost {
    userId: string;
    email: string;
    firstName: string;
    lastName: string;
}

export interface IUserWebSettings {
    featureOptions: string;
    featureOptions2: string;
    featureOptions3: string;
    featureOptions4: string;
    featureOptions5: string;
    featureOptions6: string;
    zrOptions1: string;
    zrOptions2: string;
    zrOptions5: string;
}

export interface IUserInfo {
    firstName: string;
    lastName: string;
    displayName: string;
    avatar: string;
    pmi: string;
    email: string;
    stype: number;
    sessionDuration: number;
    scheduleForList?: Array<IMeetingHost>;

    // https://zoomvideo.atlassian.net/browse/ZOOM-405141
    opClientOptions: string;

    // added for meeting list
    userId: string;
    accountId: string;
    haid: string;
    clusterId: string;

    timeFormat: '12' | '24';
    timeZone: string;
    dateFormat: string; // json stringified

    // xmpp
    jid: string;
    xmppToken: string;
    xmppDomain: string;

    // zpns
    pnsUrl: string;

    // pbx
    pbxDomain?: string;
    pbxToken?: {
        x_zm_aid: string;
        x_zm_haid: string;
        x_zm_client_cluster_id: string;
        access_token: string;
    };
    cmrStorage?: {
        detailLink: string;
        hasCmrEdit: boolean;
        overUsed: boolean;
        latestOverUsedDate: number;
    };

    meetingForceBreakUser: boolean;
    sessionExpireTime?: number; // unit: ms, current session will expire time

    clientId: string; // for zoom apps
}

interface ILang {
    key: string;
    value: string;
}

interface IVideoSetting {
    startWithVideoOn: boolean;
    startWithPmi: boolean;
}

interface IUserSettings {
    video: IVideoSetting;
}

type PartialType<T> = {
    [key in keyof T]?: Partial<T[key]>;
};
interface ICommonState {
    hashedUserId: string;
    userInfo: IUserInfo | null;
    userWebSettings: IUserWebSettings;
    isShowingNotifyUserInstallUI: boolean;
    updateIsAvailable: boolean;
    showSigninButton: boolean;
    isPhoneModuleLoaded: boolean;
    showJoinMeeting: boolean;
    mediaConfig: {
        speakerDeviceId: string;
        speakerDeviceLabel: string;
        speakerVolume: number;
        microphoneDeviceId: string;
        microphoneDeviceLabel: string;
    };
    windowSize: {
        width: number;
        height: number;
    };
    language: ILang;
    userSettings: IUserSettings;
    deviceList?: Array<IDevice & { isCurrentDevice: boolean }>;
}

const initialState: ICommonState = {
    hashedUserId: window.PwaConfig.uid || '',
    userInfo: null,
    userWebSettings: null,
    isShowingNotifyUserInstallUI: false,
    updateIsAvailable: false,
    showSigninButton: true,
    isPhoneModuleLoaded: false,
    showJoinMeeting: false,
    mediaConfig: {
        speakerDeviceId: getMediaDeviceId(true),
        speakerDeviceLabel: getMediaDeviceLabel(true),
        speakerVolume: 0.5,
        microphoneDeviceId: getMediaDeviceId(),
        microphoneDeviceLabel: getMediaDeviceLabel(),
    },
    windowSize: {
        width: window.innerWidth,
        height: window.innerHeight,
    },
    language: getLanguageInCookie(),
    userSettings: {
        video: {
            startWithVideoOn: easyStore.localStore.easyGet('video.startWithVideoOn', true),
            startWithPmi: false,
        },
    },
};

const commonSlice = createSlice({
    name: 'common',
    initialState,
    reducers: {
        setHashedUserId(state, { payload }) {
            state.hashedUserId = payload;
        },
        setUserInfo(state, { payload }) {
            if (isNull(payload)) {
                state.hashedUserId = '';
                state.userInfo = null;
            } else {
                state.userInfo = { ...payload };
            }
        },
        setUserWebSettings(state, action) {
            state.userWebSettings = action.payload;
        },
        setNotifyUserInstall(state, action) {
            state.isShowingNotifyUserInstallUI = !!action.payload;
        },
        setUpdateIsAvailable(state, action) {
            state.updateIsAvailable = !!action.payload;
        },
        setIsPhoneLoaded(state, action) {
            state.isPhoneModuleLoaded = action.payload;
        },
        setShowJoinMeeting(state, action) {
            state.showJoinMeeting = !!action.payload;
        },
        setMediaConfig(state, { payload }) {
            if (payload.microphoneDeviceId !== undefined) {
                easyStore.localStore.easySet(storageKey.microphoneDeviceId, payload.microphoneDeviceId);
            }
            if (payload.microphoneDeviceLabel !== undefined) {
                easyStore.localStore.easySet(storageKey.microphoneDeviceLabel, payload.microphoneDeviceLabel);
            }
            if (payload.speakerDeviceLabel !== undefined) {
                easyStore.localStore.easySet(storageKey.speakerDeviceLabel, payload.speakerDeviceLabel);
            }
            if (payload.speakerDeviceId !== undefined) {
                easyStore.localStore.easySet(storageKey.speakerDeviceId, payload.speakerDeviceId);
            }
            state.mediaConfig = { ...state.mediaConfig, ...payload };
        },
        setWindowSize(state, { payload }) {
            state.windowSize = {
                ...state.windowSize,
                ...payload,
            };
        },
        updateUserSettings(state, { payload }: PayloadAction<PartialType<IUserSettings>>) {
            Object.entries(payload).forEach(([key, value]) => {
                Object.assign(state.userSettings[key], value);
            });
        },
        setDeviceList(state, { payload }) {
            state.deviceList = payload;
        },
    },
});

export const {
    setHashedUserId,
    setUserInfo,
    setUserWebSettings,
    setNotifyUserInstall,
    setUpdateIsAvailable,
    setIsPhoneLoaded,
    setShowJoinMeeting,
    setMediaConfig,
    setWindowSize,
    updateUserSettings,
    setDeviceList,
} = commonSlice.actions;

export * from './common-selector';
export * from './userWebSettingsSelector';
export * from './common-thunk';
export * from './common-utils';
export default commonSlice.reducer;
