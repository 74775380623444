import microApp from '@micro-zoe/micro-app';
import { isTeslaMode } from '../utils';
import { interceptMicroAppFetch } from '../utils/MicroApp/index';

const excludeCSSAsset = (assetUrl: string) => {
    // returning true tells microapp to leave the file untouched
    // and this css will be added to document as <link />
    if (!/\.css/.test(assetUrl)) {
        return false;
    }

    /*
        returning false means microapp will take over this file
        and fetch this file's content, then add to document with <style>
        but here comes another problem,
        if we have url(../aa.png), that png  path will be resolved against top document's domain
        rathen than it's container css's path domain.
        eg.
        top document: zoomdev.us/wc/home
        css path: cdn/web/xx.css
        png path with <link>: cdn/web/aa.png
        png path with <style>: zoomdev.us/wc/aa.png (incorrect)
    */
    return true;
};

const filters = [excludeCSSAsset];

if (!isTeslaMode()) {
    microApp.start({
        'disable-memory-router': true,
        'disable-patch-request': true,
        excludeAssetFilter(assetUrl) {
            return filters.some((f) => f(assetUrl));
        },
        fetch: interceptMicroAppFetch,
        iframeSrc: '/wc/pwa-empty-page',
    });
}
