import { getDomainFromJid, isTeslaMode } from '../../utils/index';
import { IUserInfo } from './common-store';
import { BrowserDetect } from '../../utils/index';

export enum LoginMethod {
    Facebook = 0,
    Google = 1, //do not support now.
    GoogleOAuth = 2,
    Phone = 11,
    Wechat = 21,
    QQ = 22,
    ALiPay = 23,
    Apple = 24,
    Zdm = 26,
    Microsoft = 27,
    Zoom = 100,
    SSO = 101,
}

export enum CheckLoginResult {
    Pass = 0, // allowed login method
    Reject, // current login method is rejected
    RejectAll, // all login methods are rejected
}
export const changeCurrentUrl = (url: string): void => {
    window.history.replaceState({}, window.document.title, url);
};

const getBrowserName = () => {
    if (BrowserDetect.isEdge()) {
        return 'Edge';
    }
    if (BrowserDetect.isFirefox()) {
        return 'Firefox';
    }
    if (BrowserDetect.isSafai()) {
        return 'Safari';
    }
    if (BrowserDetect.isOpera()) {
        return 'Opera';
    }
    if (BrowserDetect.isChromeOS()) {
        return 'Chromebook';
    }
    if (BrowserDetect.isChrome()) {
        return 'Chrome';
    }
    return '';
};

export const getXmppConfigFromUserInfo = (
    userInfo: IUserInfo,
): { XMPPServer: string; PINGServer: string; deviceName: string } => {
    const { xmppDomain, jid } = userInfo;
    const serverAddr = `wss://${xmppDomain}/xmpp-websocket`;
    const pingAddr = getDomainFromJid(jid);
    return {
        XMPPServer: serverAddr,
        PINGServer: pingAddr,
        deviceName: getBrowserName() + '_PWA',
    };
};

/**
 * this is called , when pwa is first loaded by tesla, and set name to cookie
 */
export const captureAppLoadParams = () => {
    if (isTeslaMode()) {
        const url = new URL(window.location.href);
        const name = url.searchParams.get('name');
        if (name) {
            console.log('tesla load name:', name);
            sessionStorage.setItem('teslaData', JSON.stringify({ name }));
        }
    }
};

export const copyString = (phoneNumber: string) => {
    // don't use react-copy-to-clipboard, it has ax issue, using keyboard operation won't copy successfully
    navigator.permissions
        // @ts-ignore
        .query({ name: 'clipboard-write' })
        .then((result) => {
            if (result.state === 'granted' || result.state === 'prompt') {
                /* write to the clipboard now */
                navigator.clipboard.writeText(phoneNumber);
            }
        });
};

export function isVaildEmail(email: string) {
    // eslint-disable-next-line no-useless-escape
    const emailformat = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (email?.trim().match(emailformat)) {
        return true;
    } else {
        return false;
    }
}
