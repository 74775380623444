import store from '../store';
import { meetingAgent as _meetingAgent } from '../features/Meeting/init-meeting-agent';
import { xmppAgent as _xmppAgent } from '../services/xmpp/init-xmppAgent';
import Zpns from '../services/zpns/zpns';
import InvitationManager from '../features/Meeting/InvitationManager';
export { default as featureOptionManager } from '../utils/featureOptions';
import BroadcastChannelAgent from '../utils/BroadcastChannel/BroadcastChannelAgent';
import WhiteboardAgent from '../features/Whiteboard/WhiteboardAgent';

export const broadcastChannelAgent = new BroadcastChannelAgent('PWA_BROADCAST_CHANNEL');
export const xmppAgent = _xmppAgent;
export const meetingAgent = _meetingAgent;
export const whiteboardAgent = new WhiteboardAgent();
export const zpns = new Zpns({ store: store });
export const invitationManager = new InvitationManager({ store });

if (process.env.NODE_ENV === 'development') {
    (window as any).xmppAgent = xmppAgent;
    (window as any).meetingAgent = meetingAgent;
    (window as any).zpns = zpns;
    (window as any).invitationManager = invitationManager;
}
