import React, { useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { RoutePath } from '../../routes/routePath';
import Whiteboard from './Whiteboard';
import WhiteboardLoading from './WhiteboardLoading';

function useWhitebaordFirstVisited() {
    const [visited, updateVisited] = useState(false);
    const isWb = useRouteMatch(RoutePath.Whiteboards);

    if (!visited && isWb) {
        updateVisited(true);
    }

    return visited;
}

export default () => {
    const visited = useWhitebaordFirstVisited();
    const isMatch = useRouteMatch(RoutePath.Whiteboards);

    if (!visited) {
        return null;
    }

    const style = {
        display: isMatch ? 'block' : 'none',
    };

    return (
        <div className="pwa-wb" style={style}>
            <WhiteboardLoading />
            <Whiteboard />
        </div>
    );
};
