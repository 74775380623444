import { replaceRoute } from '../../../routes';
import { isInStandaloneMode, openTab, reload } from '../../../utils';
import { getRouteFromURL } from '../../Meetings/Meetings/utils';

export const goToPostAttendeeUrl = (postAttendeeUrl: string) => {
    if (!postAttendeeUrl) {
        return;
    }
    if (isInStandaloneMode()) {
        openTab(postAttendeeUrl);
        return;
    }
    reload(postAttendeeUrl);
};

export const removeParams = (paramKey: string) => {
    const url = new URL(location.href);
    const params = new URLSearchParams(url.search);
    params.delete(paramKey);
    replaceRoute(getRouteFromURL(location.origin + location.pathname + '?' + params.toString()));
};

export const getGPUInfo = () => {
    try {
        let canvas = document.createElement('canvas');
        const glCtx = canvas.getContext('webgl');
        canvas = null;
        if (glCtx) {
            const debugInfo = glCtx.getExtension('WEBGL_debug_renderer_info');
            const renderer = glCtx.getParameter(debugInfo.UNMASKED_RENDERER_WEBGL);
            const vendor = glCtx.getParameter(debugInfo.UNMASKED_VENDOR_WEBGL);
            return `${vendor} ${renderer.toLowerCase()}`;
        }
        return '';
    } catch (e) {
        console.error(e);
        return '';
    }
};

export const supportTouch = (): boolean =>
    (window.PointerEvent && 'maxTouchPoints' in navigator && navigator.maxTouchPoints > 0) ||
    (window.matchMedia && window.matchMedia('(any-pointer:coarse)').matches);
