import { RoutePath } from '../routes/routePath';

export enum DOM_KEY_CODE {
    ESC = 27,
    ENTER = 13,
    TAB = 9,
    UP = 38,
    DOWN = 40,
    LEFT = 37,
    RIGHT = 39,
    CANCEL = 3,
    SPACE = 32,
    BACKSLASH = 220,
    BACKSAPCE = 8,
    DELETE = 46,
}

export enum EVENT_KEYS {
    ENTER = 'Enter',
    BACKSPACE = 'Backspace',
}

export enum FetchStatus {
    idle = 'IDLE', // means this api has never been called
    loading = 'LOADING',
    succeeded = 'SUCCEEDED',
    failed = 'FAILED',
}

export enum A11Y_FOCUS_WALKER_POLICY {
    LIST_ITEM_POLICY = 0,
    LIST_POLICY = 1,
    TAB_POLICY = 2,
    LIST_ITEM_CHILD_NODE_POLICY = 3,
    NORMAL_NODE_POLICY = 4,
    PREV_TRIGGER_POLICY = 5,
    NEXT_TRIGGER_POLICY = 6,
    LIST_CHECKBOX_ITEM_POLICY = 7,
    TAB_TO_FIRST_CHILD_POLICY = 8,
    LIST_TREE_VIEW_POLICY = 9,
    PP_NEXT_CHILD_POLICY = 10,
    LIST_POLICY_2 = 11,
    PP_NEXT_CHILD_POLICY_2 = 12,
    MENU_POLICY = 13,
    NORMAL_NODE_POLICY_2 = 14,
    LIST_ITEM_CONTENT_POLICY = 15,
    TRANSCRIPT_ITEM_POLICY = 16,
}

export const TOAST_PATH = {
    GLOBAL: '/home',
    PHONE: RoutePath.Phone,
    CHAT: RoutePath.Chat,
};

export const WC_COOKIE_KEY = {
    NAME: 'wc_dn',
    EMAIL: 'wc_el',
};

export const headerTabType = {
    home: 'main',
    docs: 'docs',
    contactCenter: 'contact_center',
    chat: 'chat',
    phone: 'phone',
    meetings: 'meetings',
    contacts: 'contacts',
    whiteboards: 'whiteboards',
};

export const headerShiftOrder = [
    headerTabType.whiteboards,
    headerTabType.contacts,
    headerTabType.meetings,
    headerTabType.phone,
    headerTabType.chat,
    headerTabType.docs,
    headerTabType.contactCenter,
    headerTabType.home,
];
