import { useEffect } from 'react';
import { xmppAgent, zpns } from '../../app-init';
import { AppDispatch } from '../../store';
import { getContactCenterModule } from '../../store/appModules/contact-center';
import { getUserWebSettingsThunk, getXmppConfigFromUserInfo, IUserInfo } from '../../store/common/common-store';
import { maybeShowJoinOnlyToast } from '../Home/JoinOnlyToast';
import { refreshListThunk } from '../Meetings/Meetings/redux/meetings-thunk';
import { cameraControlGroupHelper } from '../Contacts/utils/CameraControlGroupHelper';

interface ISharedProps {
    dispatch: AppDispatch;
    userInfo: IUserInfo;
    hashedUserId: string;
}

export const useInitMeetingList = ({ dispatch, userInfo }: ISharedProps) => {
    useEffect(() => {
        if (!userInfo) {
            return;
        }
        dispatch(refreshListThunk());
    }, [userInfo?.userId]);
};

export const useShowJoinOnlyToast = ({ userInfo }: ISharedProps) => {
    useEffect(() => {
        maybeShowJoinOnlyToast(userInfo);
    }, []);
};

export const useInitXmpp = ({ userInfo }: ISharedProps) => {
    useEffect(() => {
        if (userInfo?.jid) {
            xmppAgent.init(getXmppConfigFromUserInfo(userInfo));
            xmppAgent.connect({ jid: userInfo.jid, xmppToken: userInfo.xmppToken });
        }
        return () => {
            if (!xmppAgent.isInitiated()) {
                return;
            }
            xmppAgent.uninit();
        };
    }, [userInfo?.userId]);
};

export const useGetWebSettings = ({ dispatch, hashedUserId }: ISharedProps) => {
    useEffect(() => {
        if (!hashedUserId) {
            return;
        }
        dispatch(getUserWebSettingsThunk())
            .then(() => {
                getContactCenterModule();

                // get fecc group data, the feature depends on web settings op flag and useId
                cameraControlGroupHelper.pwaGetCameraControlGroupData();

                return;
            })
            .catch((reason) => {
                console.error(reason);
            });
    }, []);
};

export const useInitZpns = ({ userInfo }: ISharedProps) => {
    useEffect(() => {
        if (userInfo) {
            zpns.connect();
        }
        return () => {
            if (!zpns.isInitialized) {
                return;
            }
            zpns.disconnect();
        };
    }, [userInfo?.userId]);
};
