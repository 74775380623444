import { WebimPresenceType } from '@zoom/pwa-webim';
import React, { KeyboardEventHandler, useRef, useState } from 'react';
import FocusLock from 'react-focus-lock';
import { connect } from 'react-redux';
import { xmppAgent } from '../../../app-init';
import { CONTACTS_DND, HOURS_TEXT_F, MINUTES_TEXT_F, TURN_OFF } from '../../../resource';
import { RootState } from '../../../store';
import { makeA11yListInfoInjector } from '../../../utils';
import { A11Y_FOCUS_WALKER_POLICY } from '../../../utils/constants';
import { PresenceIcon } from '../../../components/Presence';

type IDndPanel = {
    containerDataAL: string;
    dndDuration: number;
    onChangeStatus: (status: WebimPresenceType) => void;
    onChangeDndDuration: () => void;
};

const DndPanel = ({ containerDataAL, dndDuration, onChangeStatus, onChangeDndDuration }: IDndPanel) => {
    const containerRef = useRef(null);
    const [showDuration, setShowDuration] = useState(false);
    const { dataALGenerator } = makeA11yListInfoInjector(containerDataAL, containerRef);

    const formatMinutes = (minutes: number) => {
        const h = Math.floor(minutes / 60);
        const m = minutes % 60;
        return (h <= 0 ? '' : `${h}h `) + (m <= 0 ? '' : `${m}m`);
    };

    const changeDndDuration = (duration: number) => {
        xmppAgent.changeDndDuration(duration);
        onChangeDndDuration && onChangeDndDuration();
    };
    const parentA11yOption = {
        'data-a-l': containerDataAL,
        'data-a-walk-policy': A11Y_FOCUS_WALKER_POLICY.LIST_ITEM_POLICY,
    };

    const handleESCPressed: KeyboardEventHandler = (e) => {
        if (e.key === 'Escape') {
            setShowDuration(false);
            e.stopPropagation();
        }
    };

    return (
        <div
            className="dnd-duration"
            onMouseEnter={() => setShowDuration(true)}
            onMouseLeave={() => setShowDuration(false)}
        >
            <button
                key="dnd-duration__title"
                className="dnd-duration__title"
                aria-label={CONTACTS_DND}
                onClick={() => setShowDuration(!showDuration)}
                {...(showDuration ? {} : parentA11yOption)}
            >
                <div className="dnd-duration__title__text">
                    <PresenceIcon presenceType={'dnd'} className={'presence-icon'} />
                    {CONTACTS_DND}
                </div>

                {+dndDuration ? (
                    <span className="dnd-duration__title__duration">{formatMinutes(dndDuration)}</span>
                ) : null}
            </button>
            {showDuration && (
                <FocusLock disabled returnFocus={true}>
                    <div
                        className="dnd-duration__list"
                        onClick={() => onChangeStatus(WebimPresenceType.dnd)}
                        onKeyDown={handleESCPressed}
                        ref={containerRef}
                        {...parentA11yOption}
                    >
                        {+dndDuration > 0 ? (
                            <>
                                <button
                                    onClick={() => changeDndDuration(0)}
                                    data-a-walk-policy={A11Y_FOCUS_WALKER_POLICY.LIST_ITEM_POLICY}
                                    data-a-l={dataALGenerator()}
                                >
                                    {TURN_OFF}
                                </button>
                                <div className="info-panel-divider"></div>
                            </>
                        ) : null}
                        <button
                            onClick={() => changeDndDuration(20)}
                            data-a-walk-policy={A11Y_FOCUS_WALKER_POLICY.LIST_ITEM_POLICY}
                            data-a-l={dataALGenerator()}
                        >
                            {MINUTES_TEXT_F(20)}
                        </button>
                        {[1, 2, 4, 8, 24].map((hours) => (
                            <button
                                onClick={() => changeDndDuration(hours * 60)}
                                key={hours}
                                data-a-walk-policy={A11Y_FOCUS_WALKER_POLICY.LIST_ITEM_POLICY}
                                data-a-l={dataALGenerator()}
                            >
                                {HOURS_TEXT_F(hours)}
                            </button>
                        ))}
                    </div>
                </FocusLock>
            )}
        </div>
    );
};

const mapStateToProps = (state: RootState) => {
    const {
        contacts: { myPresenceInfo },
    } = state;
    return {
        dndDuration: myPresenceInfo?.dndDuration,
    };
};

export default connect(mapStateToProps)(DndPanel);
