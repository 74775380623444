import React, { forwardRef } from 'react';
import './start-meeting-option-panel.scss';
import ZmCheckbox from '../../components/Checkbox/Checkbox';
import FocusLock from 'react-focus-lock';
import { DOM_KEY_CODE } from '../../utils/constants';
import { OverlayInjectedProps } from 'react-bootstrap/esm/Overlay';
import { useAppSelector, useAppDispatch } from '../../store/store-hooks';
import { shallowEqual } from 'react-redux';
import PMIMenuTrigger from './PmiMenuTrigger';
import { updateUserSettingsThunk } from '../../store/common/common-thunk';
import { START_WITH_VIDEO_TEXT } from '../../resource';

interface Props extends OverlayInjectedProps {
    close(): void;
    ariaId: string;
}

export default forwardRef<HTMLDivElement, Props>(({ close, ariaId, style }: Props, ref) => {
    const dispatch = useAppDispatch();
    const { pmi, videoOn } = useAppSelector((state) => {
        const userInfo = state.common.userInfo;
        const userSettings = state.common.userSettings;
        return {
            pmi: userInfo.pmi,
            videoOn: userSettings.video.startWithVideoOn,
        };
    }, shallowEqual);

    const onKeyDown = (e: React.KeyboardEvent) => {
        if (e.keyCode === DOM_KEY_CODE.ESC) {
            close();
        }
    };

    const onVideoOnChange = () => {
        dispatch(
            updateUserSettingsThunk({
                video: {
                    startWithVideoOn: !videoOn,
                },
            }),
        );
    };

    return (
        <div ref={ref} style={style} id={ariaId} onKeyDown={onKeyDown} className="home-main-new-meeting-option-panel">
            <FocusLock disabled returnFocus={true}>
                <div className="new-meeting-option-panel-block">
                    <ZmCheckbox text={START_WITH_VIDEO_TEXT} checked={videoOn} onChange={onVideoOnChange} />
                </div>

                {pmi && <PMIMenuTrigger className="new-meeting-option-panel-block" pmi={pmi} close={close} />}
            </FocusLock>
        </div>
    );
});
