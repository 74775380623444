import { getUniqueId } from '../../utils/index';
// import { handlePbxMsgThunk } from '../../features/Phone/redux/phone-store';
import { getUserInfoThunk, signoutThunk, showOTPNotificationThunk } from '../../store/common/common-thunk';
import { zpnsEventHandlerMeetingsThunk } from '../../features/Meetings/Meetings/redux/meetings-store';
import { setUserInfo } from '../../store/common/common-store';
import { SyncType } from '../../features/Phone/Sms/types';
import { getPwaZpnsCapability } from './capability';
const ZPNS_PING_PAYLOAD = 'PING_PING_PING';
export const ZpnsMsgType = {
    push: 'push',
    login: 'login',
    loginAck: 'login-ack',
};

export const ZpnsMsgCategory = {
    Pbx: 1,
    Zpns: 2,
    Mail: 4,
    Calendar: 8,
    Ucs: 16,
    Web: 32,
    Async: 64,
    Meeting: 128,
    UserSetting: 256,
    Zrp: 512,
    Cci: 1024,
    Zr: 2048,
    Integration: 4096,
    Marketplace: 8192,
};

const ZpnsStatus = {
    Connecting: 'connecting',
    Connected: 'connected',
    Disconnecting: 'disconnecting',
    Disconnected: 'disconnected',
};

const Zpns_Max_Reconect_Times = 5;
const Zpns_Reconnect_Interval = 5 * 1000;
const Zpns_Reconnect_Random_Max = 5000;
export default class Zpns {
    _websocket = null;

    _reconnectTimes = 0;
    _reconnectTimer = null;
    _canIReconnect = true;

    _pingErrorTimes = 0;
    _pingTimer = null;
    _waitPongTimer = null;

    _loginAck = null;
    store = null;
    status = ZpnsStatus.Disconnected;

    constructor({ store }) {
        this.store = store;
    }

    isInitialized() {
        return !!this._websocket;
    }

    connect = (userInfo) => {
        this._canIReconnect = true;
        this._connect(userInfo);
    };

    _connect = (userInfo) => {
        const state = this.store.getState();
        if (!this._canIReconnect) {
            console.log('[zpns _connect] can not connect');
            return;
        }
        if (!userInfo) {
            userInfo = state.common.userInfo;
        }

        if (!userInfo?.pnsUrl || !userInfo?.xmppToken) {
            console.log('Zpns connect', 'Not signin');
            return;
        }

        let { pnsUrl, xmppToken } = userInfo;
        if (!pnsUrl || !xmppToken) {
            console.log('[zpns cancel connect, no credentials]');
            return;
        }

        let url = '';
        if (pnsUrl.toLowerCase().startsWith('wss://')) {
            url = pnsUrl;
        } else {
            url = `wss://${pnsUrl}`;
        }
        console.log('[zpns connect]');
        this._websocket = new WebSocket(url);
        this._websocket.onopen = this.onOpen;
        this._websocket.onclose = this.onClose;
        this._websocket.onmessage = this.onMessage;
        this._websocket.onerror = this.onError;
        this._changeStatus(ZpnsStatus.Connecting);
    };

    disconnect = () => {
        console.log('[zpns disconnect]');
        // if caller's manually disconnect, do not reconnect.
        this.stopPing();
        this._canIReconnect = false;
        window.clearTimeout(this._reconnectTimer);
        this._reconnectTimer = null;

        this._disconnect();
    };

    _disconnect = () => {
        console.log('[zpns _disconnect] ');
        this._changeStatus(ZpnsStatus.Disconnecting);
        if (this._websocket) {
            if (this._websocket.readyState === WebSocket.CONNECTING || this._websocket.readyState === WebSocket.OPEN) {
                this._websocket.close();
            }
        }
    };

    _changeStatus = (status, payload) => {
        this.status = status;

        if (status === ZpnsStatus.Connecting) {
            this._loginAck = payload;
            this.startPing();
        }

        if (status === ZpnsStatus.Connected) {
            this._pingErrorTimes = 0;
            this._reconnectTimes = 0;
            // Now that we have successfully connected, do not continue trying to reconnect.
            window.clearTimeout(this._reconnectTimer);
            this._reconnectTimer = null;
            window.removeEventListener('online', this.onNetworkChange);
            window.addEventListener('online', this.onNetworkChange);
        }

        if (status === ZpnsStatus.Disconnecting) {
            this.stopPing();
        }

        if (status === ZpnsStatus.Disconnected) {
            this.stopPing();
            this._reconnect();
        }
    };

    _reconnect = () => {
        if (!navigator.onLine) {
            console.log('[zpns _reconnect]', 'waiting network');
            return;
        }

        // maybe some reasons, that we cann't reconnect. such as auth error, or server tells not to reconnect
        // we should set this flag when receive server message
        if (!this._canIReconnect) {
            console.log('[zpns _reconnect] can not connect');
            return;
        }

        if (this._reconnectTimer) {
            console.log('[zpns _reconnect]', 'already scheduled to reconnect, just pass this request');
            return;
        }

        if (this.status === ZpnsStatus.Connecting) {
            console.log('[zpns _reconnect]', 'is reconecting, just pass this request');
            return;
        }

        this._reconnectTimes = this._reconnectTimes + 1;

        if (this._reconnectTimes > Zpns_Max_Reconect_Times) {
            console.log('[zpns _reconnect] can not connect, exceeds max reconnect times');
            return;
        }

        const interval = Zpns_Reconnect_Interval + Math.floor(Math.random() * Zpns_Reconnect_Random_Max);
        this._reconnectTimer = setTimeout(() => {
            console.log(
                '[zpns _reconnect]',
                `performing scheduled task ${this._reconnectTimer} | times: ${this._reconnectTimes}`,
            );
            // reset last connection's state
            this._disconnect();
            this._connect();
            this._reconnectTimer = null;
        }, interval);

        console.log(
            '[zpns _reconnect]',
            `scheduled a task in ${interval} | timerId - ${this._reconnectTimer} |  times: ${this._reconnectTimes}`,
        );
    };

    onOpen = (e) => {
        console.log('[zpns open]', e);
        const {
            common: { userInfo },
        } = this.store.getState();

        let { jid, userId, xmppToken, email } = userInfo;
        if (!userId) {
            userId = jid.split('@')[0];
        }
        if (!userId) {
            console.error('user required');
            return;
        }

        let params = {
            type: ZpnsMsgType.login,
            user: userId.toLowerCase(),
            token: xmppToken,
            id: getUniqueId('pwa-zpns'),
            option: getPwaZpnsCapability(),
            resource: 'ZoomChat_Web',
            platform: 'browser',
            deviceid: 'device_id',
            version: window.buildVersion,
            mail: email,
        };
        this.send(params);
    };

    /**
     * like xmpp connection, if another user signs in and connect to xmpp server via websocket, xmpp server will close existing connection with a close message.
     * if you connect to zpns with same account in another place, server will clolse existing connection too, but without any messages.
     * but on onClose message, we have a event
     * {
     *  type: 'close',
     *  reason: '',
     *  code: 1006
     * }
     *
     * https://datatracker.ietf.org/doc/html/rfc6455#section-7.4.1
     * 1006 is a reserved value and MUST NOT be set as a status code in a
     * Close control frame by an endpoint.  It is designated for use in
     * applications expecting a status code to indicate that the
     * connection was closed abnormally, e.g., without sending or
     * receiving a Close control frame.
     */
    onClose = (e) => {
        console.log('[zpns closed]', e);
        this._changeStatus(ZpnsStatus.Disconnected);
    };

    onError = (e) => {
        console.log('[zpns error]', e);
    };

    onNetworkChange = () => {
        if (navigator.onLine) {
            console.log('[zpns network resume]');
            this._disconnect();
            this._reconnect();
        }
    };

    onMessage = ({ data }) => {
        const {
            common: { isPhoneModuleLoaded },
        } = this.store.getState();

        data = JSON.parse(data);

        if (data === ZPNS_PING_PAYLOAD) {
            // if it's pong
            this.onReceivePong();
        }

        if (!data.type) return;

        console.log('[Zpns message]', data);

        if (data.type === ZpnsMsgType.loginAck) {
            this._changeStatus(ZpnsStatus.Connected, data);
            if (isPhoneModuleLoaded) {
                const {
                    sms: { chats: smsChats },
                } = this.store.getState();

                import(/* webpackChunkName: "phone" */ '../../features/Phone').then(
                    ({ fetchMessagesThunk, fetchChatsThunk }) => {
                        this.store.dispatch(fetchChatsThunk({ syncType: SyncType.FSync }));
                        if (smsChats.selectedChatId) {
                            this.store.dispatch(
                                fetchMessagesThunk({ sessionId: smsChats.selectedChatId, syncType: SyncType.FSync }),
                            );
                        }
                    },
                );
            }
        }

        if (
            ZpnsMsgType.push === data.type &&
            (ZpnsMsgCategory.Calendar === data.category ||
                ZpnsMsgCategory.Meeting === data.category ||
                ZpnsMsgCategory.UserSetting === data.category ||
                ZpnsMsgCategory.Zr === data.category)
        ) {
            this.store.dispatch(zpnsEventHandlerMeetingsThunk(data));
        }

        if (ZpnsMsgType.push === data.type && ZpnsMsgCategory.Pbx === data.category) {
            if (isPhoneModuleLoaded) {
                import(/* webpackChunkName: "phone" */ '../../features/Phone').then(({ handlePbxMsgThunk }) => {
                    this.store.dispatch(handlePbxMsgThunk(data.event));
                });
            }
        }

        if (ZpnsMsgType.push === data.type && ZpnsMsgCategory.Web === data.category) {
            if (data.name === '/account/cmr/limit') {
                this.store.dispatch(getUserInfoThunk()).then((userInfo) => {
                    this.store.dispatch(setUserInfo(userInfo));
                });
            }
        }

        if (
            ZpnsMsgType.push === data.type &&
            ZpnsMsgCategory.Web === data.category &&
            data.name === 'otp_notification'
        ) {
            this.store.dispatch(showOTPNotificationThunk(data.event));
        }

        console.log('[zpns msg] ', data);
    };

    send(msg) {
        if (!this._websocket || this._websocket.readyState !== WebSocket.OPEN) {
            return;
        }
        if (typeof msg === 'object') {
            this._websocket.send(JSON.stringify(msg));
        } else {
            this._websocket.send(msg);
        }
    }

    startPing = () => {
        let interval = ((this._loginAck && this._loginAck.ping) || 120) * 1000;
        this._pingTimer = setInterval(this.sendPing, interval);
    };

    stopPing = () => {
        clearInterval(this._pingTimer);
        clearTimeout(this._waitPongTimer);
        this._pingErrorTimes = 0;
    };

    sendPing = () => {
        this.send(ZPNS_PING_PAYLOAD);
        console.log('[zpns ping]');
        this._waitPongTimer = setTimeout(() => {
            this.pingError();
        }, 1200);
    };

    pingError = () => {
        this._pingErrorTimes = this._pingErrorTimes + 1;
        if (this._pingErrorTimes >= 3) {
            this._disconnect();
        } else {
            console.log('[zpns ping] error');
        }
    };

    onReceivePong = () => {
        clearTimeout(this._waitPongTimer);
        this._pingErrorTimes = 0;
    };
}
