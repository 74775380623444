import { CHAT_APP_NAME, DocsAppName, getDocsEntryHtmlUrl, getTeamChatEntryUrl } from '../../configs';
import {
    CHAT_HISTORY_NAME,
    CONTACT_CENTER_NAME,
    getPreviewHtmlUrl,
    getZCCUrl,
} from '../../store/appModules/contact-center/config';

const MICRO_APP_WHITE_LIST = {
    [CHAT_APP_NAME]: () => window.chatInitConfig.teamChatEntryUrl,
    [CONTACT_CENTER_NAME]: getZCCUrl,
    [DocsAppName]: getDocsEntryHtmlUrl,
    [CHAT_HISTORY_NAME]: getPreviewHtmlUrl,
};

const includeCredentialsForEntryHtmlFetch = (url: string, options: any) => {
    const willInclude =
        url.startsWith(getZCCUrl()) || url.startsWith(getDocsEntryHtmlUrl()) || url.startsWith(getTeamChatEntryUrl());

    if (willInclude) {
        return Object.assign({}, options, {
            credentials: 'include',
        });
    } else {
        return options;
    }
};

const isHtmlEntry = (cache: string) => cache && cache === 'no-cache';

const isSameOrigin = (url: string, appName: string) => {
    try {
        const { origin: currentOrigin } = new URL(url);
        const { origin = '' } = new URL(MICRO_APP_WHITE_LIST[appName]());
        return origin && currentOrigin === origin;
    } catch (error) {
        console.warn('isSameOrigin error ==>', error);
    }

    return false;
};

const isTextHtml = (contentType: string) => contentType.startsWith('text/html');

const entrySameOriginRule = (url: string, options: Record<string, unknown>, appName: string) => {
    const { cache } = options || {};

    if (isHtmlEntry(cache as string) && !isSameOrigin(url, appName)) {
        return false;
    }

    return true;
};

const entryResponseHtmlRule = (options: Record<string, unknown>, headers: Headers) => {
    const { cache } = options || {};
    const contentType = headers.get('content-type') || '';
    if (isHtmlEntry(cache as string) && !isTextHtml(contentType)) {
        return false;
    }

    return true;
};

export const interceptMicroAppFetch = (url: string, options: Record<string, unknown>, appName: string) => {
    // micro-app will auto add { cache: 'no-cache' } options when construct micro app.
    // so we can intercept micro-app's fetch and check the options's cache value, other intercept fetch have not cache field.
    // https://github.com/micro-zoe/micro-app/blob/dev/src/source/loader/html.ts
    if (!entrySameOriginRule(url, options, appName)) {
        console.warn('Micro App entry XSS !!!', url, options, appName);
        return Promise.resolve('');
    }

    return window
        .fetch(url, includeCredentialsForEntryHtmlFetch(url, options))
        .then((res) => {
            // attacker may get allowed filesa and tamper it, such as add some src in zoom svg file, so may cause a XSS.
            // so check the entry link fetch response content-type is better. the chat entry response file is html.
            if (!entryResponseHtmlRule(options, res.headers)) {
                console.warn('Micro App entry response XSS !!!', url, options, options);
                return Promise.resolve('');
            }

            return res.text();
        })
        .catch((error) => {
            console.warn('interceptMicroAppFetch error ==>', error);
            return Promise.resolve('');
        });
};
