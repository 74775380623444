import React, { CSSProperties } from 'react';
import classnames from 'classnames';
import { getNameAbbr, getColorIndex } from '../../utils';
import '../../assets/styles/main.scss';
import './Avatar.scss';
import ImageContainer from '../ImageContainer/ImageContainer';

interface IProps {
    isDeactive?: boolean;
    jid?: string;
    displayName: string;
    picUrl?: string;
    extraClass?: string;
    style?: CSSProperties;
    bannerComponent?: string | JSX.Element;
    isGroupChat?: boolean; // = false,
    onClickAvatar?: () => any;
    imgClass?: string;
    tabIndex?: number;
}

const Avatar = ({
    isDeactive = false,
    jid = '',
    displayName = '',
    picUrl = '',
    extraClass = '',
    style = {},
    bannerComponent = null,
    isGroupChat = false,
    onClickAvatar = () => {
        // empty
    },
    imgClass = '',
    tabIndex,
}: IProps) => {
    const getDisplayNameClass = () => {
        if (jid || displayName) {
            // if connot get jid, contact displayName、unique id also can be used.
            return `bgColor${getColorIndex(jid || displayName, isDeactive)}`;
        } else if (isGroupChat) {
            return '_avatar__group_chat';
        } else {
            return '_avatar__background';
        }
    };

    const placeHolderName: JSX.Element = (
        <span className={classnames('_avatar__name', getDisplayNameClass())}>{getNameAbbr(displayName)}</span>
    );

    return (
        <div className="_avatar" onClick={onClickAvatar} tabIndex={tabIndex}>
            <div className={classnames('_avatar__pic-wrapper', extraClass)} style={style}>
                <ImageContainer
                    url={picUrl}
                    alt="Avatar"
                    imgClass={classnames('_avatar__pic', imgClass)}
                    placeHolder={placeHolderName}
                />
            </div>
            {bannerComponent}
        </div>
    );
};

export default Avatar;
