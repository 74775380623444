/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useRef, useCallback, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/store-hooks';
import './WebClient.scss';
import { getDomain } from '../../utils/meeting-url';
import { meetingAgent } from '../../app-init';
import { setLaunchMeeting, setMeetingInfo } from './redux';
import useWebclientPosition from './useWebclientPosition';
import classNames from 'classnames';
import { Modal, ModalEvents } from 'Modal';
import { BACK } from '../../resource';
import { IconChevronLeftSmall } from '@zoom/icons-react';
import { replaceRoute } from '../../routes';
import { getUrlForPWAPortal } from './MeetingStarter';
import useSyncMeetingTopic from './useSyncMeetingTopic';
import { webclientLogger } from '../../logger/pwa-loggers';
import { PWAMeetingEvent } from './meeting-agent-msg-type';

/***
 * current:  https://zoomdev.us/wc
 * join meeting url: https://sophie.zoomdev.us/wc/join/xxxxxxxx
 * or in reversed order.
 */
const getTargetOrigin = (url: string) => {
    let targetUrl = null;
    try {
        targetUrl = new URL(url);
    } catch (e) {
        return '';
    }
    if (getDomain(window.location.hostname) === getDomain(targetUrl.hostname)) {
        return targetUrl.origin;
    }
    return '';
};

export default () => {
    const dispatch = useAppDispatch();
    const {
        meeting: { meetingUrl, meetingInfo, isMeetingMinimized },
        modal: { webclientIframeZIndex },
    } = useAppSelector((_) => _);

    const iframeRef = useRef<HTMLIFrameElement>(null);

    const { position } = useWebclientPosition({
        initialPosition: { bottom: 14, right: 14 },
        minimizedSize: { height: 135, width: 240 },
        bounds: { top: 13, left: 8, bottom: 13, right: 8 },
    });

    const [pageLoaded, setPageLoaded] = useState(false);
    const [webclientLoaded, setWebclientLoaded] = useState(false);
    const [showCover, setShowCover] = useState(false);
    const showLoading = !pageLoaded;
    const showBack = !meetingInfo && !showLoading && !webclientLoaded;

    const targetOrigin = getTargetOrigin(meetingUrl);
    // prepare communication to webclient
    const sendMsgToWebClient = useCallback(
        (data: any) => {
            if (iframeRef.current && iframeRef.current.contentWindow) {
                iframeRef.current.contentWindow.postMessage(data, targetOrigin);
            } else {
                console.error('send to wb error, no window');
            }
        },
        [iframeRef, targetOrigin],
    );

    useEffect(() => {
        webclientLogger.log('webclient iframe mounted', ['Dom']);
        return () => {
            meetingAgent.clearZakSessionIfPossible();
            webclientLogger.log('webclient iframe un-mounted', ['Dom']);
        };
    }, []);

    useEffect(() => {
        meetingAgent.setPostMessage(sendMsgToWebClient);
        return () => {
            meetingAgent.setPostMessage(null);
        };
    }, [sendMsgToWebClient]);

    const onLoad = useCallback(() => {
        setPageLoaded(true);
        window.clearInterval(timerRef.current);
    }, []);

    useEffect(() => {
        const iframeNode = iframeRef.current;
        iframeNode.addEventListener('load', onLoad);
        return () => {
            iframeNode.removeEventListener('load', onLoad);
        };
    }, [onLoad]);

    const timerRef = useRef<number>();
    useEffect(() => {
        timerRef.current = window.setInterval(() => {
            try {
                const iframe = iframeRef && iframeRef.current;
                if (iframe) {
                    const document = iframe.contentDocument;
                    const readyState = document.readyState;
                    if (readyState === 'loading') {
                        onLoad();
                    }
                }
            } catch (e) {
                window.clearInterval(timerRef.current);
            }
        }, 100);
        return () => {
            window.clearInterval(timerRef.current);
        };
    }, []);

    const handler = (e: MessageEvent) => {
        if (e.data === 'wc_loaded') {
            window.removeEventListener('message', handler);
            setWebclientLoaded(true);
        }
        if (e.data?.event === 'wc_loaded') {
            if (!window.PwaConfig?.enableMeetingRoute) {
                return;
            }
            if (e.data?.meetingNo) {
                let url = `/${e.data.meetingNo}/join`;
                if (e.data?.type === 'start') {
                    url = `/${e.data.meetingNo}/start`;
                }

                const curUrl = new URL(window.location.href);
                const searchParams = curUrl.searchParams;
                searchParams.set('fromPWA', '1');

                if (searchParams.has('transfer')) {
                    searchParams.delete('transfer');
                }

                replaceRoute({
                    pathname: url,
                    search: `?${searchParams.toString()}`,
                });

                dispatch(
                    setLaunchMeeting({
                        meetingUrlNotOnIframe: getUrlForPWAPortal(),
                    }),
                );
            }
        }
    };

    useEffect(() => {
        window.addEventListener('message', handler);
        return () => {
            window.removeEventListener('message', handler);
        };
    }, []);

    useEffect(() => {
        if (meetingInfo && meetingInfo.meetingStatus === PWAMeetingEvent.JOINING) {
            window.removeEventListener('message', handler);
            setWebclientLoaded(true);
        }
    }, [meetingInfo]);

    const onClickBack = () => {
        webclientLogger.log(
            {
                desc: 'webclient user clicks Back',
            },
            ['End'],
        );
        if (meetingInfo && meetingInfo.meetingStatus === PWAMeetingEvent.SUCCESS) {
            meetingAgent.onPwaWindowUnload();
        } else {
            meetingAgent.dispatchLaunchMeeting(null);
            dispatch(setMeetingInfo(null));
        }
    };

    const backBtn = showBack ? (
        <button className="pwa-webclient__back" onClick={onClickBack}>
            <IconChevronLeftSmall className="pwa-webclient__back-icon" />
            {BACK}
        </button>
    ) : null;

    const loadingEle = showLoading && (
        <div className="pwa-webclient__loading">
            <div className="pwa-webclient__loading-icon"></div>
        </div>
    );

    const defaultStyle = {
        zIndex: webclientIframeZIndex,
    };

    const rootStyle = Object.assign(
        defaultStyle,
        !isMeetingMinimized ? { width: '100%', height: '100%', top: 0, left: 0 } : position,
    );

    // when iframe shows up (maximined or minimized) and another draggable element shows on top of it.
    // when you drag the modal faster, mouse/touch event may go through iframe's document, which makes the drag behavior bad
    // we add an cover over the iframe, whenever we are dragging an element to prevent mouse/touch events being dispatching to iframe.
    const coverEle = !showCover ? null : (
        <div
            className="pwa-webclient__cover"
            onClick={() => {
                setShowCover(false);
            }}
        ></div>
    );

    useEffect(() => {
        const handler = ({ count }: { count: number }) => {
            if (count > 0) {
                setShowCover(true);
            } else {
                setShowCover(false);
            }
        };

        Modal.on(ModalEvents.DraggingCountUpdate, handler);

        return () => {
            Modal.off(ModalEvents.DraggingCountUpdate, handler);
        };
    }, []);

    useEffect(() => {
        Modal.emit(ModalEvents.BaseZIndexUpdate, { zindex: webclientIframeZIndex });
        return () => {
            Modal.emit(ModalEvents.BaseZIndexUpdate, { zindex: 0 });
        };
    }, []);

    useSyncMeetingTopic();

    return (
        <div className={classNames('pwa-webclient', { 'pwa-webclient--mini': isMeetingMinimized })} style={rootStyle}>
            {coverEle}
            {backBtn}
            <div className="pwa-webclient__iframe-wrapper">
                <iframe
                    ref={iframeRef}
                    className="pwa-webclient__iframe"
                    id="webclient"
                    src={meetingUrl}
                    role="presentation"
                />
            </div>
            {loadingEle}
        </div>
    );
};
