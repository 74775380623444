import React from 'react';
import {
    IconOnline,
    IconAway,
    IconBusy,
    IconInACalenderEvent,
    IconDoNotDisturb,
    IconMobile,
    IconOffline,
    IconInCall,
    IconInMeeting,
    IconOutOfOffice,
    // IconBlocked,
} from '@zoom/icons-react';
import './index.scss';
interface IPresence {
    presenceType: string;
}

export const PresenceIcon = ({ presenceType = '', className = '' }) => {
    let element: JSX.Element | null = null;
    switch (presenceType) {
        case 'room':
        case 'desktop':
        case 'available':
            element = <IconOnline className={className} aria-hidden={true} />;
            break;
        case 'offline':
            element = <IconOffline className={className} aria-hidden={true} />;
            break;
        case 'mobile':
            element = <IconMobile className={className} aria-hidden={true} />;
            break;
        case 'away':
            element = <IconAway className={className} aria-hidden={true} />;
            break;
        case 'dnd':
            element = <IconDoNotDisturb className={className} aria-hidden={true} />;
            break;
        case 'meeting':
        case 'zoommeeting':
        case 'presenting':
            element = <IconInMeeting className={className} aria-hidden={true} />;
            break;
        case 'pbx':
            element = <IconInCall className={className} aria-hidden={true} />;
            break;
        case 'calendar':
            element = <IconInACalenderEvent className={className} aria-hidden={true} />;
            break;
        case 'busy':
            element = <IconBusy className={className} aria-hidden={true} />;
            break;
        case 'outofoffice':
            element = <IconOutOfOffice className={className} aria-hidden={true} />;
            break;
        default:
            break;
    }

    return element;
};

export const PresenceBadge = ({ presenceType }: IPresence) => {
    let presenceVideo = '';
    if (presenceType === 'meeting' || presenceType === 'zoommeeting' || presenceType === 'presenting') {
        presenceVideo = 'presence__video-icon';
    }

    return (
        <span className={`presence__container ${presenceVideo}`}>
            <PresenceIcon presenceType={presenceType} />
        </span>
    );
};
