import { createSlice } from '@reduxjs/toolkit';
import { getBareJidFromJid } from '../../../utils';
import { WebimInvitation } from '@zoom/pwa-webim';
import { feedbackOptions } from '../feedback/enum';

export interface IMeetingInfo {
    mid: string;
    meetingNo: string;
    pwd: string; // encrypted password
    password: string; // plain password
    caption: string;
    displayName: string;
    startBySelf: boolean;
    meetingStatus: string; // should be ts enum
    iak?: string;
    credential?: string;
    hasBypassWaitingroomCode?: boolean;
    channelId?: string;
    isWebinar?: boolean;
    isSimuliveWebinar?: boolean;
    isHost?: boolean;
    needRegister?: boolean;
}

export interface IMeetingInvitation {
    [key: string]: any;
}

export interface ITransferMeeting {
    lcp: string;
    boID?: string;
    boToken?: string;
    boTitle?: string;
    isVideoOn?: boolean;
    isAudioOn?: boolean;
    isAudioDisconnect?: boolean;
    isWaitingRoomAudioOn?: boolean;
    isWaitingRoomVideoOn?: boolean;
}

export interface IState {
    suppressMeetingCall: boolean;
    showWebclient: boolean;
    type: 'start' | 'join' | '';
    meetingUrl: string;
    meetingUrlNotOnIframe: string;
    meetingInfo: IMeetingInfo | null;
    invitationList: Array<WebimInvitation>;
    isMeetingMinimized: boolean;
    isMeetingAudioConnected: boolean;
    isInvitedByXmpp: 0 | 1;
    isStartDisabled: boolean;
    transferMeetingData?: ITransferMeeting;
    webclientEnableFeedback: number;
    postAttendeeUrl: string;
    enableFeedbackTextField: boolean;
    feedbackCustMessage: string;
    extraData?: Record<string, any>;
}

const initialState: IState = {
    suppressMeetingCall: false,
    showWebclient: false,
    type: '',
    meetingUrl: '',
    meetingUrlNotOnIframe: '',
    isInvitedByXmpp: 0,
    meetingInfo: null,
    invitationList: [],
    isMeetingMinimized: false,
    isMeetingAudioConnected: false,
    isStartDisabled: false,
    webclientEnableFeedback: feedbackOptions.NO_FEEDBACK,
    postAttendeeUrl: '',
    enableFeedbackTextField: false,
    feedbackCustMessage: '',
};

const meetingSlice = createSlice({
    name: 'meeting',
    initialState,
    reducers: {
        setLaunchMeeting(state, { payload }) {
            if (payload) {
                Object.assign(state, payload);
            } else {
                Object.assign(state, {
                    showWebclient: false,
                    type: '',
                    meetingUrl: '',
                    meetingUrlNotOnIframe: '',
                    isInvitedByXmpp: 0,
                });
            }
        },

        setLaunchExtraData(state, { payload }) {
            Object.assign(state.extraData, payload);
        },

        setMeetingInfo(state, { payload }) {
            if (payload) {
                state.meetingInfo = { ...state.meetingInfo, ...payload };
            } else {
                state.meetingInfo = null;
            }
        },

        addInvitation(state, { payload }) {
            const index = state.invitationList.findIndex(
                (item) =>
                    item.meetingNo === payload.meetingNo &&
                    getBareJidFromJid(item.fromJid) === getBareJidFromJid(payload.fromJid),
            );
            if (index < 0) {
                state.invitationList.push(payload);
            }
        },

        removeInvitation(state, { payload }) {
            const pToJid = getBareJidFromJid(payload.toJid);
            const pFromJid = getBareJidFromJid(payload.fromJid);
            const isSameAccountOtherDevice = pToJid === pFromJid;

            const index = state.invitationList.findIndex((item) => {
                // 1. we decline by click
                // 2. invitor cancel
                // 3. our other resouce accept/decline
                if (item.meetingNo !== payload.meetingNo) {
                    return false;
                }
                const itemFromJid = getBareJidFromJid(item.fromJid);
                if (isSameAccountOtherDevice) {
                    return true;
                } else {
                    return itemFromJid === pFromJid;
                }
            });
            if (index > -1) state.invitationList.splice(index, 1);
        },

        clearInvitation(state) {
            state.invitationList = [];
        },

        setIsMeetingMinimized(state, { payload }) {
            state.isMeetingMinimized = payload;
        },

        setIsMeetingAudioConnected(state, { payload }) {
            state.isMeetingAudioConnected = payload;
        },

        setIsStartDisabled(state, { payload }) {
            state.isStartDisabled = payload;
        },

        setSuppressMeetingCall(state, { payload }) {
            state.suppressMeetingCall = Boolean(payload);
        },

        setMeetingLcp(state, { payload }) {
            state.transferMeetingData = payload;
        },
        setWebclientEnableFeedback(state, { payload }) {
            state.webclientEnableFeedback = payload;
        },
        setPostAttendeeUrl(state, { payload }) {
            state.postAttendeeUrl = payload;
        },
        setEnableFeedbackTextField(state, { payload }) {
            state.enableFeedbackTextField = payload;
        },
        setFeedbackCustMessage(state, { payload }) {
            state.feedbackCustMessage = payload;
        },
    },
});

export const {
    setLaunchMeeting,
    setLaunchExtraData,
    setMeetingInfo,
    addInvitation,
    removeInvitation,
    clearInvitation,
    setIsMeetingMinimized,
    setIsMeetingAudioConnected,
    setIsStartDisabled,
    setSuppressMeetingCall,
    setMeetingLcp,
    setWebclientEnableFeedback,
    setPostAttendeeUrl,
    setEnableFeedbackTextField,
    setFeedbackCustMessage,
} = meetingSlice.actions;

export default meetingSlice.reducer;
