/**
 * https://zoomvideo.atlassian.net/wiki/spaces/ZWC/pages/2424965623/PWA+Portal+Feature+Option+Config
 */

import { isSupportBusyPresenceEnabled, isSupportOOOPresenceEnabled } from './op_client_location';
import { isThisBitSetByOffset } from '../longNumBitOps';
import { PWA_CLIENT_FEATURE_OPTIONS } from './Constant';

const INVALID_VALUE = '-1';
export default class FeatureOptionsManager {
    opClientOptions = INVALID_VALUE; // jave long type to in decimal string format
    pwaClientFeatureOptions = INVALID_VALUE;

    constructor() {
        this.pwaClientFeatureOptions = window.PwaConfig.pwaClientFeatureOptions || '0';
    }

    isInvalidValue(value: string) {
        return value === INVALID_VALUE;
    }

    setOpClientOptions(options: string) {
        if (/^\d+$/.test(options)) {
            this.opClientOptions = options;
        }
    }

    setPwaClientFeatureOptions(options: string) {
        if (/^\d+$/.test(options)) {
            this.pwaClientFeatureOptions = options;
        }
    }

    isBusyPresenceOn(defaultValue = false) {
        if (this.isInvalidValue(this.opClientOptions)) {
            return defaultValue;
        }

        return isSupportBusyPresenceEnabled(this.opClientOptions);
    }

    // for OOO Presence
    isOOOPresenceOn(defaultValue = false) {
        if (this.isInvalidValue(this.opClientOptions)) {
            return defaultValue;
        }
        return isSupportOOOPresenceEnabled(this.opClientOptions);
    }

    private verifyOptions(offset: number, defaultValue = false) {
        if (this.isInvalidValue(this.pwaClientFeatureOptions)) {
            return defaultValue;
        }

        // old options is single number, such as 0, 2, 7, that need transfer to binary format.
        // To compatible old config， keep this logic
        if (this.pwaClientFeatureOptions.length === 1) {
            return isThisBitSetByOffset(this.pwaClientFeatureOptions, 16)(offset);
        }

        // new options is '0'/'1' string, such as '01010111'. and the string length is more than 1.
        // 0: enable， 1 disable
        // read from right to left
        const options = this.pwaClientFeatureOptions.split('').reverse().join('');
        return options.charAt(offset) === '1';
    }

    // for pwa ab option, 0001 (1 << 0)
    isPhoneSMSOn() {
        return this.verifyOptions(PWA_CLIENT_FEATURE_OPTIONS.PHONE_SMS);
    }

    // for pwa ab option, 0010 (1 << 1)
    isInviteContactOn() {
        return this.verifyOptions(PWA_CLIENT_FEATURE_OPTIONS.INVITE_CONTACT);
    }

    // for pwa ab option, 0100 (1 << 2)
    isFarEndCameraControlGroupOn() {
        return this.verifyOptions(PWA_CLIENT_FEATURE_OPTIONS.FAR_END_CAMERA_CONTROL);
    }

    // for pwa ab option, 1000 (1 << 3)
    isSupportZccApps() {
        return this.verifyOptions(PWA_CLIENT_FEATURE_OPTIONS.ZCC_APPS);
    }

    /*
     * 6.0.0, we release first version of docs integration, but docs deploy one week later than pwa
     * this should be cluster level
     * mask: 10000(1 << 4)
     */
    isDocsDeployed() {
        return this.verifyOptions(PWA_CLIENT_FEATURE_OPTIONS.Docs);
    }
}
