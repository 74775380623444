import type { FetchStatus } from '../../utils/constants';
import { WebimPresenceType } from '@zoom/pwa-webim';

export enum DisplayType {
    contact,
    search,
}

export enum GroupFrom {
    unset = 0,
    xmpp = 1,
    ucs = 2,
    cloud = 4,

    local = 5, // frome local IndexDB,
}

/**
 * work location
 * '0' : turn off, '1': office, '2': remote
 * */
export enum WorkLocation {
    turnOff = '0',
    office = '1',
    remote = '2',
}

export type JID = string;
export type GROUP_ID = string;
export type IPhoneNumber = string;

export enum LABLE_GROUP {
    MY = 'my',
    SHARED = 'shared',
    ALL = 'all',
}

export enum CONTACT_TYPE {
    NA, // not availabel
    ADD_ON,
}
export interface ILABLE_GROUP_ITEM {
    id: GROUP_ID;
    groupFrom: GroupFrom;
}
export interface IContact_ZoomPhone_Pbx {
    ext: IPhoneNumber; // extension number
    dn: Array<IPhoneNumber>; // direct number
    cn: IPhoneNumber; // company number
}

export interface IContact_Profile_Ext {
    adr: string; // address
    audit: boolean;
    audit_t: string;
    cmpy: string; // company
    dept: string; // department
    job: string;
    manager: string;
    platform: string;
    pronoun: string;
    wkcode: string;
    wkphone: string;
    info?: {
        timezoneid: string;
    };
}

export interface IContact_Profile_WKphone {
    code: string;
    country: string;
    label: string;
    text: string;
    type: string;
    verified: boolean;
}

export interface MapByJid<T> {
    [jid: JID]: T;
}

export interface IPresence {
    jid: JID;
    presenceType: WebimPresenceType;
}

export interface IXmppContact {
    jid: string;
    firstName: string;
    lastName: string;
    type: number;
    cmd: string;
}
export interface IXmppGroup {
    id: string;
    name: string;
    total: number;
    option: string;
    type: string;
    version: string;
    items: Array<IXmppContact>;
}

export interface IXmppVcard {
    jid: string;
    displayName: string;
    lastName: string;
    firstName: string;
    picUrl: string;
    email: string;
    signature: {
        text: string;
    };
    intro: string;
    userType: string;

    phone: {
        sip: string;
        normal: string;
        work: string;
        home: string;
    };
    orgUnit: Array<string>;
    ext: IContact_Profile_Ext;
    pbx: IContact_ZoomPhone_Pbx;
    wkphones: Array<IContact_Profile_WKphone>;
}

export interface IGroup {
    id: string;
    name: string;
    type: number;
    version: string; // curent version, local version
    total: number; // member count. if -1, mean lazy load, need trigger.

    status: number;
    option: number;
    startVersion: string;

    // utility property
    groupFrom: GroupFrom;
    nextCursor: number; // for ucs group lazy loading by page
}
export interface IContact_Compact {
    jid: JID;
    type: number;
    cmd?: string;
    firstName: string;
    lastName: string;
}

export interface IContact_ZoomPhone {
    // from xmpp
    phone: {
        sip: string;
        normal: string;
        work: string;
        home: string;
    };

    // from web search
    pbx: IContact_ZoomPhone_Pbx;
    phoneNumbers: Array<{ phoneNumber: IPhoneNumber; countryCode: string }>; // {phoneNumber: "18566246732", countryCode: "1"}; // countryCode is wrong
    cellPhoneNumber: IPhoneNumber; // "+86 18566246732"
    sipPhone: Record<string, unknown>;
    countryCode: Record<string, unknown>;
}

export interface IContact_ProfileInfo {
    nickName?: string;
    ext?: IContact_Profile_Ext;
}

export interface IContact extends IContact_Compact, IContact_ZoomPhone, IContact_ProfileInfo {
    jid: JID;
    displayName: string;
    picUrl: string;
    email: string;
    signature: {
        text: string;
    };
    action: string;
    userType: string;
    orgUnit: Array<string>;
    isExternal?: boolean;

    // for add on
    intro: string;

    //
    terminate: number;
    inactive: number;
    hidden: number;
    blocked: number;

    // from web search
    isSameAccount: number;
    contactType: number;
    ibOptions: number;
    isMasterOrSub: number;
    bindWithMobile: number;

    companyNumber: string;
    directNumber: Array<string>;
    extension: string;

    ext: IContact_Profile_Ext;
    wkphones: Array<IContact_Profile_WKphone>;
}

export interface IMyPresenceInfo {
    dndDuration: number;
}

export interface IGroupLoadingState {
    id: string;
    cursor: number;
    status: FetchStatus;
    limit?: number;
}

export interface ISelectedContact {
    jid: JID;
    groupId?: GROUP_ID;
}

export interface ISelectedGroup {
    groupId: GROUP_ID;
}

export type ISelectedItem = ISelectedContact | ISelectedGroup;

export interface IContactState {
    currentDisplayType: DisplayType;

    myPresenceInfo: IMyPresenceInfo;
    presences: MapByJid<IPresence>;

    groups: Record<string, IGroup>;
    contacts: Record<JID, IContact>;
    groupToContactTable: Record<GROUP_ID, Array<JID>>;
    labelToGroupTable: Record<string, Array<ILABLE_GROUP_ITEM>>;
    ui: {
        groups: Record<GROUP_ID, { folded: boolean }>;
    };

    groupsLoadingState: Record<string, IGroupLoadingState>;

    isReceivedGroupsData: boolean;

    selectedContact: ISelectedContact;
    selectedItem: ISelectedItem;

    searchStatus: FetchStatus;
    searchKey: string;
    scrollTop: number;
    searchResult: Array<IContact>;

    pendingContacts?: Array<IContact>;
}

// REMOVE
// need to remove
export interface IContactSearchFromWeb {
    jid: JID;
    displayName: string;
    firstName: string;
    lastName: string;

    type: number;
    picUrl: string;
    snsEmail: string;
    isSameAccount: number;

    contactType: number;

    pbx: string; // json stringified (IContactSearchFromWebPbx)
    phoneNumbers: Array<{ phoneNumber: IPhoneNumber; countryCode: string }>; // {phoneNumber: "18566246732", countryCode: "1"}; // countryCode is wrong
    cellPhoneNumber: IPhoneNumber; // "+86 18566246732"
    sipPhone: Record<string, unknown>;
    countryCode: Record<string, unknown>;

    ibOptions: number;
    isMasterOrSub: number;
    bindWithMobile: number;
}

export interface IContactProfileParams {
    jid: string;
    lastModify: number;
}

export type JidArray = Array<{ jid: string }>;

export interface IContactDetailData {
    currentContact: IContact;
    showWebclient: boolean;
    isDuringMeeting: boolean;
    presenceType: WebimPresenceType;
    presenceText: string;
    isJoinOnly: boolean;
    isAddon: boolean;
    canShowChatBtn: boolean;
    isStartDisabled: boolean;
}

export enum CONTACT_MEMBER_OPTIONS_FROM {
    CONTACT = 'CONTACT',
    PENDIND_CONTACT = 'PENDIND_CONTACT',
}

export interface IContact_MemberOptions {
    showFarEndCameraControlGroup: boolean;
    showDeleteContact: boolean;
}
