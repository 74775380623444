import { ReactElement } from 'react';
import { matchPath, useHistory, useLocation } from 'react-router-dom';
import { RoutePath } from '../../../routes';

interface IProps {
    enabled: boolean;
    render: () => ReactElement;
    path: string;
    exact?: boolean;
    redirectPath?: string;
}

export default (props: IProps) => {
    const { path = '', exact = true, enabled = false, redirectPath = RoutePath.Home, render } = props;
    const location = useLocation();
    const history = useHistory();

    const isMatch = matchPath(location.pathname, {
        path,
        exact,
    });

    if (enabled) {
        return render();
    }

    if (!enabled && isMatch) {
        history.push({
            pathname: redirectPath,
        });
        return null;
    }

    return null;
};
