import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { useSelector } from 'react-redux';
import FocusLock from 'react-focus-lock';
import '../../assets/styles/main.scss';
import './JoinMeeting.scss';

import { addSpaceToMeetingId, isTeslaMode } from '../../utils/index';
import { meetingAgent } from '../../app-init';
import { DOM_KEY_CODE } from '../../utils/constants';
import { JOIN_MEETING, MEETING_ID_PLN, JOIN, CANCEL, Meeting_List_Toggle_Text } from '../../resource';
import { Button } from 'react-bootstrap';
import { DRIVE_WARNING_TEXT } from '../../vendors/tesla/resource';
import { IconChevronDownSmall, IconChevronUpSmall } from '@zoom/icons-react';
import MeetingHistory, { historyItem } from './MeetingHistory/MeetingHistory';
import { JoinMeetingDBManager } from './JoinMeetingDBManager';
import { useFingerPrint } from './useFingerPrint';
import { routeBack } from '../../routes';
import { isUserSigninSelector, setShowJoinMeeting } from '../../store/common/common-store';
import { withFocusWalker } from '../../hoc/withFocusWalker';
import { keyBoardEventHandler } from '../../utils/keyboard-event';
import { useAppDispatch, useAppSelector } from '../../store/store-hooks';
import HeaderLogo from '../SignedIn/Header/HeaderLogo';
import { getMeetingNoFromMeetingUrl } from '../../utils/meeting-url';
import { webclientLogger } from '../../logger/pwa-loggers';
import { PWAMeetingEvent } from '../Meeting/meeting-agent-msg-type';

const PersonLinkUpperLimit = 40;
const personalLinkReg = /^[a-zA-Z][a-zA-Z0-9.\-_]{4,39}$/;
const meetingIdReg = /^\d{9,11}$/;

interface IProps {
    footerLink?: React.ReactElement;
    [key: string]: any;
}

function JoinMeeting({ footerLink }: IProps): JSX.Element {
    const [meetingId, setMeetingId] = useState('');
    const [isShowDropDown, setIsShowDropDown] = useState(false);
    const [selectedMeetingIdx, setSelectedMeetingIdx] = useState(0);
    const [historyList, setHistoryList] = useState<Array<historyItem>>([]);
    const dispatch = useAppDispatch();
    const isUserSignin = useAppSelector(isUserSigninSelector);
    const toogleIcon4MeetingListRef = useRef<HTMLSpanElement>(null);
    const _meetingId = meetingId.replace(/[\s]/g, '');
    const pastedUrlParamsRef = useRef({});
    const { showWebclient, meetingInfo, isMeetingMinimized } = useSelector((state: any) => state.meeting);

    const goBack = (): void => {
        if (window.PwaConfig?.enableMeetingRoute) {
            routeBack();
        } else {
            dispatch(setShowJoinMeeting(false));
        }
    };

    useFingerPrint();

    const isTesla = isTeslaMode();

    const isReady = personalLinkReg.test(_meetingId) || meetingIdReg.test(_meetingId);

    const changeMeetingId = (id: string): void => {
        id = id.replace(/[\s]/g, '');
        if (/^[\d]/.test(id) && /^\d+$/.test(id)) {
            // for meeting url
            id = id.slice(0, 11);
            setMeetingId(addSpaceToMeetingId(id));
        } else if (/^[a-zA-Z]/.test(id) && /^[a-zA-Z0-9.\-_]+$/.test(id)) {
            // for personal link url
            id = id.slice(0, PersonLinkUpperLimit);
            setMeetingId(id);
        } else if (id === '') {
            setMeetingId('');
        }
    };

    const onMeetingIdChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        changeMeetingId(e.target.value);
        pastedUrlParamsRef.current = {};
    };

    const onPaste = (e: React.ClipboardEvent): void => {
        const content = (e.clipboardData && e.clipboardData.getData('text')) || '';
        let meetingId = '';
        try {
            // it's a link https://pwa.zoom.us/j/123456789?pwd=xxxxxxxxxx
            const url = new URL(content);
            const isHttps = url.protocol === 'https:';
            const isValidDomain = [
                /^([^.]+\.)*zoom(dev)?(\.[^.]+?)+$/, // yyy.xxx.zoom.us, zoomdev.us, zoom.com.cn, zoom.de
                /^([^.]+\.)*zoomgov(dev)?(\.[^.]+?)+$/,
            ].some((_) => _.test(url.hostname));

            const isNativePathPrefix = ['/j/', '/s/', '/w/', '/my/'].some((_) => url.pathname.startsWith(_));
            const isWebclientPathPrefix = ['/wc/'].some((_) => url.pathname.startsWith(_));
            const isValidPathPrefix = isNativePathPrefix || isWebclientPathPrefix;
            if (!isHttps || !isValidDomain || !isValidPathPrefix) {
                throw Error('');
            }

            if (isNativePathPrefix) {
                meetingId = url.pathname.split('/').pop() || '';
            }

            if (isWebclientPathPrefix) {
                meetingId = getMeetingNoFromMeetingUrl(url.pathname);
            }

            pastedUrlParamsRef.current = Array.from(url.searchParams.entries()).reduce((acc, [key, value]) => {
                acc[key] = value;
                return acc;
            }, {});
        } catch {
            // it's not a link
            const url = content.split('?')[0];
            meetingId = url.split('/').pop() || '';
            pastedUrlParamsRef.current = {};
        }

        if (meetingId) {
            changeMeetingId(meetingId);
        }
        e.preventDefault();
    };

    const onClickJoinMeeting = (): void => {
        if (isReady) {
            const id = meetingId.replace(/\s/g, '');
            const params = pastedUrlParamsRef.current;
            let isPersonLink = false;
            if (meetingIdReg.test(id)) {
                meetingAgent.joinMeeting(id, params);
            } else if (personalLinkReg.test(id)) {
                isPersonLink = true;
                meetingAgent.joinMetingPersonalLink(id, params);
            }
            webclientLogger.log(
                {
                    from: 'Join Page',
                    isPersonalLink: isPersonLink,
                    desc: 'manually',
                },
                ['Join'],
            );
        }
    };

    const handleHistoryItemClicked = (item: historyItem, index: number) => {
        changeMeetingId(item.meetingNo.toString());
        setIsShowDropDown(false);
        setSelectedMeetingIdx(index);
    };

    const onInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>): void => {
        if (e.keyCode === DOM_KEY_CODE.ENTER) {
            onClickJoinMeeting();
        }
    };

    const onToggleKeyDown = (e: React.KeyboardEvent) => {
        if (e.keyCode === DOM_KEY_CODE.ENTER || e.keyCode === DOM_KEY_CODE.SPACE) {
            setIsShowDropDown(!isShowDropDown);
        } else if (e.keyCode === DOM_KEY_CODE.ESC) {
            setIsShowDropDown(false);
        }
    };

    const handleClearHistory = () => {
        JoinMeetingDBManager.clear();
        setIsShowDropDown(false);
        setHistoryList([]);
    };

    const meetingIdRef = useRef<HTMLInputElement>(null);

    // if you join a meeting from here (by providing meeting number manually)
    // and you join successfully, we switch back to your previous UI
    useLayoutEffect(() => {
        if (
            meetingInfo?.meetingStatus === PWAMeetingEvent.SUCCESS &&
            (!window.PwaConfig?.enableMeetingRoute || isMeetingMinimized)
        ) {
            goBack();
        }
    }, [meetingInfo, isMeetingMinimized]);

    // if you join a meeting from here (by providing meeting number manually)
    // if you choose to back to pwa before you get to webclient
    // we should auto select your previous meeting number
    useEffect(() => {
        if (!showWebclient) {
            if (meetingIdRef.current) {
                meetingIdRef.current.select();
            }
        }
    }, [showWebclient]);

    useLayoutEffect(() => {
        if (!isUserSignin) {
            setHistoryList([]);
            return;
        }

        JoinMeetingDBManager.getHistoryMeetingList().then((list) => {
            if (!list?.length) {
                return;
            }
            setHistoryList(list);
        });
    }, [isUserSignin]);

    useEffect(() => {
        if (!isShowDropDown) {
            toogleIcon4MeetingListRef?.current?.focus();
        }
    }, [isShowDropDown]);

    return (
        <FocusLock returnFocus disabled={true || showWebclient}>
            <div className="join-page">
                <div className="home-header">
                    <HeaderLogo />
                </div>
                <div className="join">
                    <h2 className="join-title">{JOIN_MEETING}</h2>
                    <div className="join-form" id="joinMeeting">
                        <div className="join-meetingId-container">
                            <input
                                ref={meetingIdRef}
                                type="text"
                                className="join-meetingId"
                                placeholder={MEETING_ID_PLN}
                                value={meetingId}
                                onChange={onMeetingIdChange}
                                onPaste={onPaste}
                                onKeyDown={onInputKeyDown}
                            />
                            {historyList.length > 0 && (
                                <>
                                    <span
                                        className="join-meeting__dropdown-toggle"
                                        tabIndex={0}
                                        onKeyDown={onToggleKeyDown}
                                        aria-label={Meeting_List_Toggle_Text}
                                        role="button"
                                        aria-haspopup="listbox"
                                        aria-expanded={isShowDropDown}
                                        onClick={(e) => {
                                            e?.stopPropagation();
                                            setIsShowDropDown(!isShowDropDown);
                                        }}
                                        ref={toogleIcon4MeetingListRef}
                                    >
                                        {isShowDropDown ? <IconChevronUpSmall /> : <IconChevronDownSmall />}
                                    </span>
                                    {isShowDropDown ? (
                                        <MeetingHistory
                                            clearHistory={handleClearHistory}
                                            historyList={historyList}
                                            selectedMeetingIdx={selectedMeetingIdx}
                                            onClickHistoryItem={handleHistoryItemClicked}
                                            hideMe={() => setIsShowDropDown(false)}
                                        />
                                    ) : null}
                                </>
                            )}
                        </div>
                        {isTesla ? <div className="drive-warning">{DRIVE_WARNING_TEXT}</div> : null}
                    </div>
                    <footer>
                        <Button variant="light" className="btn-cancel" onClick={goBack}>
                            {CANCEL}
                        </Button>
                        <Button disabled={!isReady} className="btn-join" onClick={onClickJoinMeeting}>
                            {JOIN}
                        </Button>
                    </footer>
                </div>
                <div className="join-page__footer">{footerLink}</div>
            </div>
        </FocusLock>
    );
}

export default withFocusWalker(JoinMeeting, 'joinMeeting', () => true, ['.join-meetingId'], keyBoardEventHandler);
