import { isChatEnabled, isWhiteBoardEnabled, isZCCEnabled } from '../utils/featureOptions';

export const RoutePath = {
    Index: '/', // for user not signin
    Home: '/home',
    Phone: '/phone',
    Chat: window.PwaConfig.enableTeamChatUrl ? '/team-chat' : '/chat',
    Meetings: '/meetings',
    Contacts: '/contacts',
    Whiteboards: '/whiteboards',
    ContactCenter: '/contact-center',
    StartMeeting: '/:meetingNumber/start',
    JoinMeeting: '/:meetingNumber/join',
    JoinMeeting2: '/join/:meetingNumber',
    StartVideoMeeting: '/start/videomeeting',
    StartWebMeeting: '/start/webmeeting',
    MyMeeting: '/my/:userName',
    Join: '/join',
    Docs: '/docs',
};

export const AuthRoutes = [
    RoutePath.Home,
    RoutePath.Phone,
    RoutePath.Meetings,
    RoutePath.Contacts,
    RoutePath.Whiteboards,
    RoutePath.Chat,
    RoutePath.ContactCenter,
    RoutePath.Docs,
].filter((_) => !!_);

const NonAuthRoutes = [RoutePath.Index, RoutePath.Join];

export const PortalRoute = [].concat(NonAuthRoutes, AuthRoutes);

export const MeetingRoutes = [
    RoutePath.StartMeeting,
    RoutePath.JoinMeeting,
    RoutePath.JoinMeeting2,
    RoutePath.StartVideoMeeting,
    RoutePath.StartWebMeeting,
    RoutePath.MyMeeting,
];
