import { ICurrentCall } from '.';

export enum ZCCInComingMessageFunctionName {
    CCIUIAPI_IncomingCall = 'CCIUIAPI_IncomingCall',
    CCIUIAPI_UpdateIncomingCall = 'CCIUIAPI_UpdateIncomingCall',
    CCIUIAPI_UseAudio = 'CCIUIAPI_UseAudio',
    CCIUIAPI_GetUserProfile = 'CCIUIAPI_GetUserProfile',
    CCIUIAPI_CancelIncomingCall = 'CCIUIAPI_CancelIncomingCall',
    CCIUIAPI_LogMessage = 'CCIUIAPI_LogMessage',

    CCIUIAPI_OnCallStart = 'CCIUIAPI_OnCallStart',
    CCIUIAPI_OnCallUpdate = 'CCIUIAPI_OnCallUpdate',
    CCIUIAPI_OnCallEnd = 'CCIUIAPI_OnCallEnd',

    CCIUIAPI_GetZoomCallStatus = 'CCIUIAPI_GetZoomCallStatus',
    CCIUIAPI_GetAudioOccupancyStatus = 'CCIUIAPI_GetAudioOccupancyStatus',

    CCIUIAPI_StartOutboundAudio = 'CCIUIAPI_StartOutboundAudio',
    CCIUIAPI_CloseOutboundAudio = 'CCIUIAPI_CloseOutboundAudio',

    CCIUIAPI_PresenceStatusSync = 'CCIUIAPI_PresenceStatusSync',

    PWA_CCIUIAPI_PAGE_LOADED = 'PWA_CCIUIAPI_PAGE_LOADED',
    PWA_CCIUIAPI_SESSION_EXPIRED = 'PWA_CCIUIAPI_SESSION_EXPIRED',

    CCIUIAPI_SearchBuddy = 'CCIUIAPI_SearchBuddy',
    CCIUIAPI_SubscribeBuddyPresence = 'CCIUIAPI_SubscribeBuddyPresence',
    CCIUIAPI_ClearPresenceSubscribe = 'CCIUIAPI_ClearPresenceSubscribe',

    /** zcc app releated  */
    CCIUIAPI_ZCCAppCreateEngagement = 'CCIUIAPI_ZCCAppCreateEngagement',
    CCIUIAPI_ZCCAppSwitchEngagement = 'CCIUIAPI_ZCCAppSwitchEngagement',
    CCIUIAPI_ZCCAppUpdateEngagement = 'CCIUIAPI_ZCCAppUpdateEngagement',
    CCIUIAPI_ZCCAppEndEngagement = 'CCIUIAPI_ZCCAppEndEngagement',
    CCIUIAPI_GetZCCAppList = 'CCIUIAPI_GetZCCAppList',
    CCIUIAPI_ZCCAppShow = 'CCIUIAPI_ZCCAppShow',
    CCIUIAPI_ZCCAppHide = 'CCIUIAPI_ZCCAppHide',
    CCIUIAPI_ZCCAppResize = 'CCIUIAPI_ZCCAppResize',

    /** PCIPal app related */
    CCIUIAPI_ZCCAppSetPCIPalSecureStatus = 'CCIUIAPI_ZCCAppSetPCIPalSecureStatus',
    CCIUIAPI_ZCCAppSetPCIPalMediaRedirectAPIResult = 'CCIUIAPI_ZCCAppSetPCIPalMediaRedirectAPIResult',
    CCIUIAPI_ZCCAppUpdatePCIPalMediaRedirectStatus = 'CCIUIAPI_ZCCAppUpdatePCIPalMediaRedirectStatus',
    /** PCIPal app related */

    /** zcc app releated */
    CCIUIAPI_IncomingCall_OBPreviewDialer = 'CCIUIAPI_IncomingCall_OBPreviewDialer',
    CCIUIAPI_UpdateIncomingCall_OBPreviewDialer = 'CCIUIAPI_UpdateIncomingCall_OBPreviewDialer',
    CCIUIAPI_CancelIncomingCall_OBPreviewDialer = 'CCIUIAPI_CancelIncomingCall_OBPreviewDialer',
    CCIUINotifCallStatus_OBPreviewDialer = 'CCIUINotifCallStatus_OBPreviewDialer',
}

export enum ETaskType {
    Phone = 'phone',
    Video = 'video',
    Chat = 'chat',
    Sms = 'sms',
    Email = 'email',
    Facebook = 'facebook',
    Whatsapp = 'whatsapp',
    Instagram = 'instagram',
    TeamChat = 'teamchat',
}
interface ZCCIncomingMessageBase {
    jsCallId: string;
    functionName: ZCCInComingMessageFunctionName;
    params: Record<string, any>;
}

interface IComingCallParams {
    brandName?: string;
    callTypeText?: string;
    from: string;
    fromName: string;
    fromLocation: string;
    fromMask: string;
    showMask: boolean;
    showMaskEye: boolean;
    fromMaskDuration: number;
    locationMask: string;
    showLocationMask: boolean;
    showLocationMaskEye: boolean;
    locationMaskDuration: number;
    flowNumber: string;
    taskSid: string;
    taskType: ETaskType;
    taskIconType: ETaskType;
    toQueue: string;
    isTransfer: boolean;
    isCallback: boolean;
    transferFrom: string;
    transferFromName: string;
    previewContent: string;
    previewContactDetailsUrl: string;
    isConference: boolean;
    conferenceInfo: string;
    isVerified: boolean;
    outboundDialer?: {
        [key: string]: any;
    };
    summary: string;
    summaryLoading: boolean;
    previewHtml: string;
    metaDataArr: any[];
    transferNotes: string;
    extMask?: string;
    fromExt?: string;
    fromQueue?: string;
}

export interface IComingCall_OBPreviewDialerParams {
    brandName: string;
    fromExt: string;
    extMask: string;
    from: string;
    fromName: string;
    fromLocation: string;
    fromMask: string;
    showMask: boolean;
    showMaskEye: boolean;
    fromMaskDuration: number;
    locationMask: string;
    showLocationMask: boolean;
    showLocationMaskEye: boolean;
    locationMaskDuration: number;
    flowNumber: string;
    taskSid: string;
    taskType: ETaskType;
    taskIconType: ETaskType;
    callTypeText: string;
    fromQueue: string; // new
    isTransfer: boolean;
    isCallback: boolean;
    transferFrom: string;
    transferFromName: string;
    previewContent: string;
    previewContactDetailsUrl: string;
    isConference: boolean;
    conferenceInfo: string;
    isVerified: boolean;
    supportAudio: boolean;
    summary: string;
    summaryLoading: boolean;
    transferNotes: string;
    metaDataArr: any[];
    autoAccept: boolean;

    // new: outbound preview dialer
    outboundDialer: {
        contactId: string; // customer identity, distinguish between different contacts
        previewDialingStrategy: string; //  `Automatic` or `Manual`,  origin: dialingStrategy
        previewTimer: number; // seconds, Only if the `previewDialingStrategy` is "Automatic" will the `previewTimer` be passed.  origin: previerTime
        enableSkip: boolean; // origin: skipAllow
        // Only if the `enableSkip` is true will the following params be passed.
        skipReasons: Array<{
            id: string;
            name: string;
        }>;
        skipNum: number; // current number of clicks
        maxSkips: number; // maximum number of clicks
        enableIgnore: boolean;
        role: string;
        location: string;
        company: string;
        accountNumber: string;
    };
}

export interface IComingCall_OBPreviewDialerParams_WithJsCallId extends IComingCall_OBPreviewDialerParams {
    jsCallId: string;
    canPlayRingTone: boolean;
}

export interface IComingCallParams_WithJsCallId extends IComingCallParams {
    jsCallId: string;
    canPlayRingTone: boolean;
}

export interface ZCCIncomingMessage_IncomingCall extends ZCCIncomingMessageBase {
    functionName: ZCCInComingMessageFunctionName.CCIUIAPI_IncomingCall;
    params: IComingCallParams;
}

export interface ZCCIncomingMessage_UpdateIncomingCall extends ZCCIncomingMessageBase {
    functionName: ZCCInComingMessageFunctionName.CCIUIAPI_UpdateIncomingCall;
    params: IComingCallParams;
}

export interface ZCCIncomingMessage_IncomingCall_OBPreviewDialer extends ZCCIncomingMessageBase {
    functionName: ZCCInComingMessageFunctionName.CCIUIAPI_IncomingCall_OBPreviewDialer;
    params: IComingCall_OBPreviewDialerParams;
}

export interface ZCCIncomingMessage_UpdateIncomingCall_OBPreviewDialer extends ZCCIncomingMessageBase {
    functionName: ZCCInComingMessageFunctionName.CCIUIAPI_UpdateIncomingCall_OBPreviewDialer;
    params: IComingCall_OBPreviewDialerParams;
}

export interface ZCCIncomingMessage_CallStartAndUpdate extends ZCCIncomingMessageBase {
    functionName:
        | ZCCInComingMessageFunctionName.CCIUIAPI_OnCallStart
        | ZCCInComingMessageFunctionName.CCIUIAPI_OnCallUpdate;
    params: ICurrentCall;
}

export interface ZCCIncomingMessage_CallEnd extends ZCCIncomingMessageBase {
    functionName: ZCCInComingMessageFunctionName.CCIUIAPI_OnCallEnd;
    params: ICurrentCall;
}

export interface IUseAudioParams {
    taskType: 'phone' | 'video';
    audioStatus: number; // 0 | 1
    taskSid: string;
    ts: number;
}

export interface ZCCIncomingMessage_UseAudio extends ZCCIncomingMessageBase {
    functionName: ZCCInComingMessageFunctionName.CCIUIAPI_UseAudio;
    params: IUseAudioParams;
}

export interface ZCCIncomingMessage_LogMessage extends ZCCIncomingMessageBase {
    functionName: ZCCInComingMessageFunctionName.CCIUIAPI_LogMessage;
    params: {
        level: 'INFO' | 'LOG';
        log: string;
    };
}

export interface ZCCIncomingMessage_GetUserProfile extends ZCCIncomingMessageBase {
    functionName: ZCCInComingMessageFunctionName.CCIUIAPI_GetUserProfile;
    params: null;
}

export interface ZCCIncomingMessage_CancelIncomingCall extends ZCCIncomingMessageBase {
    functionName: ZCCInComingMessageFunctionName.CCIUIAPI_CancelIncomingCall;
    params: {
        taskSid: string;
        taskType: ETaskType;
    };
}

export interface ZCCIncomingMessage_CancelIncomingCall_OBPreviewDialer extends ZCCIncomingMessageBase {
    functionName: ZCCInComingMessageFunctionName.CCIUIAPI_CancelIncomingCall_OBPreviewDialer;
    params: {
        taskSid: string;
        taskType: ETaskType;
    };
}

export interface ZCCIncomingMessage_PageLoaded extends ZCCIncomingMessageBase {
    functionName: ZCCInComingMessageFunctionName.PWA_CCIUIAPI_PAGE_LOADED;
    params: null;
}

export interface ZCCIncomingMessage_SessionExpired extends ZCCIncomingMessageBase {
    functionName: ZCCInComingMessageFunctionName.PWA_CCIUIAPI_SESSION_EXPIRED;
    params: null;
}

export enum EClientZmCallStatusType {
    MeetingStatus_Idle = 1,
    MeetingStatus_Connecting,
    MeetingStatus_OnCall,
    PhoneStatus_On,
    PhoneStatus_Off,
}

export interface ZCCIncomingMessage_GetZoomCallStatus extends ZCCIncomingMessageBase {
    functionName: ZCCInComingMessageFunctionName.CCIUIAPI_GetZoomCallStatus;
    params: null;
}

export enum EAudioOccupyStatus {
    NA,
    Meeting,
    Phone,
    ZCC,
}

export interface ZCCIncomingMessage_GetAudioOccupancyStatus extends ZCCIncomingMessageBase {
    functionName: ZCCInComingMessageFunctionName.CCIUIAPI_GetAudioOccupancyStatus;
    params: null;
}

export interface ZCCIncomingMessage_StartOutboundAudio extends ZCCIncomingMessageBase {
    functionName: ZCCInComingMessageFunctionName.CCIUIAPI_StartOutboundAudio;
    params: {
        taskSid: string;
        taskType: 'phone' | 'video';
    };
}

export interface ZCCIncomingMessage_CloseOutboundAudio extends ZCCIncomingMessageBase {
    functionName: ZCCInComingMessageFunctionName.CCIUIAPI_CloseOutboundAudio;
    params: null;
}

enum ZCCPresenceValue {
    End,
    Start,
}
interface ZCCPresence {
    type: ETaskType;
    value: ZCCPresenceValue;
}
export interface ZCCIncomingMessage_PresenceStatusSync extends ZCCIncomingMessageBase {
    functionName: ZCCInComingMessageFunctionName.CCIUIAPI_PresenceStatusSync;
    params: {
        arrStatus: Array<ZCCPresence>;
        suppressZoomCall: boolean; // if to suppress zoom phone and video call notification when zcc busy with zcc voice/video
    };
}

export interface ZCCIncomingMessage_SearchBuddy extends ZCCIncomingMessageBase {
    functionName: ZCCInComingMessageFunctionName.CCIUIAPI_SearchBuddy;
    params: {
        searchKey: string;
        contactType: string;
    };
}

export interface ZCCIncomingMessage_SubscribeBuddyPresence extends ZCCIncomingMessageBase {
    functionName: ZCCInComingMessageFunctionName.CCIUIAPI_SubscribeBuddyPresence;
    params: {
        jids: Array<string>;
    };
}

export interface ZCCIncomingMessage_ClearPresenceSubscribe extends ZCCIncomingMessageBase {
    functionName: ZCCInComingMessageFunctionName.CCIUIAPI_ClearPresenceSubscribe;
    params: null;
}

export interface ZCCIncomingMessage_GetZCCAppList extends ZCCIncomingMessageBase {
    functionName: ZCCInComingMessageFunctionName.CCIUIAPI_GetZCCAppList;
    params: {
        queueId: string;
    };
}

export interface ZCCIncomingMessage_AppHide extends ZCCIncomingMessageBase {
    functionName: ZCCInComingMessageFunctionName.CCIUIAPI_ZCCAppHide;
    params: {};
}

// https://coda.io/d/_d5wCHggE6Tg/Zoom-Apps-for-ZCC-SDK-Enhancements_sulNq#_luL16
export interface ZccEngagement {
    startTime: number;
    endTime?: number; // zcc will update this when status is 'closed'
    engagementId: string;
    status: 'active' | 'closed' | 'wrap-up';
    channel: 'messaging';
    source: 'messaging_webChat';
    queueId: string;
    queueName: string;
    acceptTime: number;
    assignedAgentId: string;
    assignedAgentName: string;
    currentAgentId: string;
    currentAgentName: string;
    consumers: {
        consumerId: string;
        consumerDisplayName: string;
        consumerNumber: string;
        consumerEmail: string;
    }[];
    isTransfer: boolean;
    transferType: string;
    transferFromAgentId: string;
    transferFromAgentName: string;
    isConference: boolean;
}

// 'notes' is deprecated, confirmed with jake.yu
// Jan/23/2024
export interface ZccEngagementNote {
    noteId: string;
    userId: string;
    userName: string;
    note: string;
    lastModifiedTime: string;
    lastModifiedUserId: string;
    lastModifiedUserName: string;
}

// 'events' is deprecated, confirmed with jake.yu
// Jan/23/2024
export interface ZccEngagementEvent {
    channelType: string; // chat | video ... etc
    eventType: string; // 'flow'
    startTime: number;
    duration: number;
    direction: 'inbound' | 'outbound';
    flowId: string;
    flowName: string;
    queueId: string;
    queueName: string;
    userId: string;
    userDisplayName: string;
    accepttype: 'manual' | 'auto';
    transferToFlowId: string;
    transferToFlowName: string;
    transferToUserId: string;
    transferToUserDisplayName: string;
    transferToNumber: string;
    transferType: 'cold' | 'warm';
    upgradedToChannelType: 'video';
    crmType: 'string';
    crmCallStatus: 'ringing' | 'answer' | 'angup' | 'miss';
    supervisorId: string;
    supervisorDisplayName: string;
    consumerId: string;
    consumerDisplayName: string;
    inboxId: string;
    inboxName: string;
}

export interface ZccApp_Dimension {
    height: number;
    width: number;
    x: number;
    y: number;
}
export type ZccAppShow_Params = {
    applicationId: string;
    switch: boolean;
} & {
    engagement: ZccEngagement;
    engagementId: string;
} & ZccApp_Dimension;

export interface ZCCIncomingMessage_AppShow extends ZCCIncomingMessageBase {
    functionName: ZCCInComingMessageFunctionName.CCIUIAPI_ZCCAppShow;
    params: ZccAppShow_Params;
}

export interface ZCCIncomingMessage_CreateEngagement extends ZCCIncomingMessageBase {
    functionName: ZCCInComingMessageFunctionName.CCIUIAPI_ZCCAppCreateEngagement;
    params: {
        engagement: ZccEngagement;
        engagementId: string;
    };
}

export interface ZCCIncomingMessage_UpdateEngagement extends ZCCIncomingMessageBase {
    functionName: ZCCInComingMessageFunctionName.CCIUIAPI_ZCCAppUpdateEngagement;
    params: {
        engagementId: string;
        engagement?: Partial<ZccEngagement>;
        notes?: any[];
    };
}

export interface ZCCIncomingMessage_SwitchEngagement extends ZCCIncomingMessageBase {
    functionName: ZCCInComingMessageFunctionName.CCIUIAPI_ZCCAppSwitchEngagement;
    params: {
        engagement: ZccEngagement;
        engagementId: string;
    };
}

export interface ZCCIncomingMessage_EndEngagement extends ZCCIncomingMessageBase {
    functionName: ZCCInComingMessageFunctionName.CCIUIAPI_ZCCAppEndEngagement;
    params: {
        engagementId: string;
    };
}

export interface ZCCIncomingMessage_AppResize extends ZCCIncomingMessageBase {
    functionName: ZCCInComingMessageFunctionName.CCIUIAPI_ZCCAppResize;
    params: {
        applicationId: string;
    } & ZccApp_Dimension;
}

export interface ZCCIncomingMessage_SetPCIPalSecureStatus extends ZCCIncomingMessageBase {
    functionName: ZCCInComingMessageFunctionName.CCIUIAPI_ZCCAppSetPCIPalSecureStatus;
    params: {
        engagementId: string;
        pciReady: boolean; // secureTransactionReady Indicates whether the call is ready for a secure transaction such as PCI
        region: string; // The media redirection region - specify actual regions here
        calleeNumber: string; // The number of the callee
        callerNumber: string; // The number of the caller
        notReadyReason: object | string;
    };
}

export interface ZCCIncomingMessage_SetStartMediaRedirectionApiResult extends ZCCIncomingMessageBase {
    functionName: ZCCInComingMessageFunctionName.CCIUIAPI_ZCCAppSetPCIPalMediaRedirectAPIResult;
    params:
        | {
              engagementId: string;
              linkId: string;
              message: 'success';
          }
        | {
              engagementId: string;
              linkId: string;
              message: 'failed';
              errorCode: number;
              errorMessage: string;
          };
}

export interface ZCCIncomingMessage_UpdatePCIPalMediaRedirectionStatus extends ZCCIncomingMessageBase {
    functionName: ZCCInComingMessageFunctionName.CCIUIAPI_ZCCAppUpdatePCIPalMediaRedirectStatus;
    params: {
        accountId: string;
        engagementId: string;
        linkId: string;
        userId: string;
        action: 'start' | 'stop';
        status: 'success' | 'fail';
        errorCode: number;
        errorMessage: string;
    };
}

export type ZCCIncomingMessage =
    | ZCCIncomingMessage_SetStartMediaRedirectionApiResult
    | ZCCIncomingMessage_UpdatePCIPalMediaRedirectionStatus
    | ZCCIncomingMessage_SetPCIPalSecureStatus
    | ZCCIncomingMessage_CreateEngagement
    | ZCCIncomingMessage_UpdateEngagement
    | ZCCIncomingMessage_SwitchEngagement
    | ZCCIncomingMessage_EndEngagement
    | ZCCIncomingMessage_AppResize
    | ZCCIncomingMessage_AppShow
    | ZCCIncomingMessage_GetZCCAppList
    | ZCCIncomingMessage_AppHide
    | ZCCIncomingMessage_SearchBuddy
    | ZCCIncomingMessage_SubscribeBuddyPresence
    | ZCCIncomingMessage_ClearPresenceSubscribe
    | ZCCIncomingMessage_GetUserProfile
    | ZCCIncomingMessage_PresenceStatusSync
    | ZCCIncomingMessage_StartOutboundAudio
    | ZCCIncomingMessage_CloseOutboundAudio
    | ZCCIncomingMessage_GetAudioOccupancyStatus
    | ZCCIncomingMessage_GetZoomCallStatus
    | ZCCIncomingMessage_IncomingCall
    | ZCCIncomingMessage_UpdateIncomingCall
    | ZCCIncomingMessage_UseAudio
    | ZCCIncomingMessage_LogMessage
    | ZCCIncomingMessage_CancelIncomingCall
    | ZCCIncomingMessage_PageLoaded
    | ZCCIncomingMessage_SessionExpired
    | ZCCIncomingMessage_CallStartAndUpdate
    | ZCCIncomingMessage_CallEnd
    | ZCCIncomingMessage_CancelIncomingCall_OBPreviewDialer
    | ZCCIncomingMessage_IncomingCall_OBPreviewDialer
    | ZCCIncomingMessage_UpdateIncomingCall_OBPreviewDialer;
